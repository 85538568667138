import React, { FunctionComponent, useEffect, useState } from "react";
import { settings } from "../../hooks/settings-context";
import usePersistedSettings from "../../hooks/usePersistedSettings";

type AppProps = {
  data: any;
  setActiveScope: any;
  appSettings: any;
  setAppSettings: any;
};

const Scopes: FunctionComponent<AppProps> = (props: AppProps) => {
  const { data, setActiveScope, appSettings, setAppSettings } = props;
  const getKeysOfJsonString = (jsonString: string) => {
    const jsonObject = JSON.parse(jsonString);
    const keys = Object.keys(jsonObject);
    return keys;
  };

  return (
    <div className="dropdown">
      {getKeysOfJsonString(data.scopes).map((scope: string) => (
        <div
          key={scope}
          onClick={() => {
            const scopeSettings = JSON.parse(appSettings.default.data?.scopes || "{}");
            const activeScopeSettings = scopeSettings?.[scope];
            localStorage.setItem("activeScope", scope);
            setActiveScope(scope);
            setAppSettings({
              ...appSettings,
              default: {
                ...appSettings.default,
                data: {
                  ...appSettings.default.data,
                  voyager_feed: scope,
                }
              },
            })
            window.location.href = "/"
          }}
          aria-hidden="true"
        >
          {scope}
        </div>
      ))}
    </div>
  );
};

export default Scopes;

import React, { useState, ReactElement } from "react";
import { Card } from "react-bootstrap";

type AppProps = {
  data: {
    widget: string;
    src: string;
    style: {
      width: string;
      height: string;
    };
  };
  settings: {
    cctv_stream_1: string;
    cctv_stream_2: string;
  };
};

export default function WidgetCardLarge({
  data,
  settings,
}: AppProps): ReactElement {
  const [changeCctv, handleChangeCctv] = useState(false);

  let sm;
  if (changeCctv === false) {
    sm = (
      <>
        <iframe
          title={data.widget}
          src={settings.cctv_stream_1}
          frameBorder="0"
          scrolling="yes"
          height="550px"
          sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
        />
        <br />
        <iframe
          title={data.widget}
          src={settings.cctv_stream_2}
          frameBorder="0"
          scrolling="yes"
          height="550px"
          sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
        />
      </>
    );
  }
  if (changeCctv === true) {
    sm = (
      <iframe
        title="timelapse"
        src="https://g0.ipcamlive.com/player/timelapse.php?alias=600ea9fb50d56&date=2021-01-10&disablevideofit=1"
        height="calc(100vh - 384px)"
        frameBorder="0"
        allowFullScreen
      />
    );
  }
  return (
    <Card className="pb-1 pt-1 px-1 full-height custom-card">
      <h6 className="custom-card__title">
        {data.widget}
        <a href="/" className="custom-btn__circular minus">
          {" "}
        </a>
      </h6>

      {data.widget === "CCTV" ? (
        sm
      ) : (
        <iframe
          title={data.widget}
          src={data.src}
          frameBorder="0"
          scrolling="yes"
          sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
          style={data.style}
        />
      )}
    </Card>
  );
}

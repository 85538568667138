/* eslint-disable react/prop-types */
import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Nav, Navbar } from "react-bootstrap";
import { settings, SettingsContext } from "../../hooks/settings-context";
import Scopes from "./Scopes";
import usePersistedSettings from "../../hooks/usePersistedSettings";

const Navigation: FC<RouteComponentProps> = (
  { location }
) => {
  const [monitorDropdown, setMonitorDropdown] = useState<boolean>(false);
  const [, setActiveScope] = useState<string>("");
  const handleScopeDropdown = () => setMonitorDropdown((prev) => !prev);
  const [appSettings, setAppSettings] = usePersistedSettings("settings", settings);

  return (
    <SettingsContext.Consumer>
      {({ data }) =>
        location.pathname === "/charts" ? (
          <></>
        ) : (
          <Navbar
            className="justify-content-between navbar-bg__dark pt-0 pb-0 pr-md-5 fixed-top"
            variant="dark"
            expand="lg"
          >
            <div>
              <Navbar.Brand href="/">
                <span>
                  <img src={data.app_logo} className="App-logo" alt="logo" />
                </span>
              </Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls="main-navbar-nav" />
            <Navbar.Collapse
              id="main-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <Nav.Link
                  className={`monitor ${location.pathname === "/" ? "active" : ""
                    } p-lg-4`}
                  href={JSON.parse(data?.multi_scopes || "false") === false ? "/" : ""}
                  onClick={handleScopeDropdown}
                >
                  Monitor {localStorage.getItem('activeScope') && `[${localStorage.getItem('activeScope')}]`}
                  {JSON.parse(data?.multi_scopes || "false") &&
                    monitorDropdown && (
                      <Scopes
                        data={data}
                        setActiveScope={setActiveScope}
                        appSettings={appSettings}
                        setAppSettings={setAppSettings}
                      />
                    )}
                </Nav.Link>
                <Nav.Link
                  className={`${location.pathname === "/telemetrics" ? "active" : ""
                    } p-lg-4`}
                  href="/telemetrics"
                >
                  Telemetrics
                </Nav.Link>
                <Nav.Link
                  className={`${location.pathname === "/weather" ? "active" : ""
                    } p-lg-4`}
                  href="/weather"
                >
                  Weather
                </Nav.Link>
                {/* <Nav.Link
                  className={`${location.pathname === "/observability" ? "active" : ""
                    } p-lg-4`}
                  href="/observability"
                >
                  Observability
                </Nav.Link> */}
                <Nav.Link
                  className={`${location.pathname === "/iss" ? "active" : ""
                    } p-lg-4`}
                  href="/iss"
                >
                  ISS
                </Nav.Link>
                <Nav.Link
                  className={`${location.pathname === "/lunar" ? "active" : ""
                    } p-lg-4`}
                  href="/lunar"
                >
                  Lunar
                </Nav.Link>
                <Nav.Link
                  className={`${location.pathname === "/solar" ? "active" : ""
                    } p-lg-4`}
                  href="/solar"
                >
                  Solar
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )
      }
    </SettingsContext.Consumer>
  );
};

export default withRouter(Navigation);

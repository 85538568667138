/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import moment from "moment";
import TempChart from "./TempChart";
import WindChart from "./WindChart";
import CloudChart from "./CloudChart";
import RainChart from "./RainChart";
import Status from "./Status";
import dataFetch from "../../../actions/fetchDataActions";
import usePersistedState from "../../../hooks/usePersistedState";
import { OPTIONS } from "../options";
import ChartDataWrapper from "./SqmSeeingDustChartWrapper";
import HFDStarIndexChart from "./HFDStarIndexChart";
import GuidingChart from "./GuidingChart";

type AppProps = {
  apiEndpoint: string;
  settings: {
    station_timezone: string;
    voyager_feed: string,
  };
  dataType: any;
};

export default function ChartWapper({
  apiEndpoint,
  dataType,
  settings,
}: AppProps): ReactElement {
  const defaultState = { error: null, status: true, items: [] };

  const [sqmData, setSqmData] = usePersistedState("sqmData", defaultState);
  const [sqmDataYes, setSqmDataYes] = usePersistedState(
    "sqmDataYes",
    defaultState
  );

  const [nsmData, setNsmData] = usePersistedState("nsmData", defaultState);
  const [nsmDataYes, setNsmDataYes] = usePersistedState(
    "nsmDataYes",
    defaultState
  );

  const [alldylosData, setAlldylosData] = usePersistedState(
    "alldylosData",
    defaultState
  );
  const [AlldylosDataYes, setAlldylosDataYes] = usePersistedState(
    "AlldylosDataYes",
    defaultState
  );

  const [soloDataYes, setSoloDataYes] = usePersistedState(
    "soloDataYes",
    defaultState
  );

  const [weatherSafety, setWeatherSafety] = usePersistedState(
    "weatherSafety",
    defaultState,
  )
  const [soloData, setSoloData] = usePersistedState("soloData", defaultState);


  const [delay, setDelay] = useState(false);
  const now = new Date();
  const before18hr = moment(now).subtract({ hours: 18 }).unix();
  const after6hr = moment(now).add({ hours: 6 }).unix();
  const pastBefore18hr = moment(moment(now).subtract({ hours: 18 }))
    .subtract({ hours: 24 })
    .unix();
  const pastAfter6hr = moment(moment(now).add({ hours: 6 }))
    .subtract({ hours: 24 })
    .unix();

  const urls = {
    recent_url: `?from=${before18hr}&to=${after6hr}`,
    past_url: `?from=${pastBefore18hr}&to=${pastAfter6hr}`,
  };

  const options = {
    ...OPTIONS,
    xaxis: {
      ...OPTIONS.xaxis,
      min: before18hr,
      max: after6hr,
    },
  };

  useEffect(() => {
    const baseUrl = `${apiEndpoint}/data`;
    // sqmData
    dataFetch(`${baseUrl}/sqm${urls.recent_url}`).then((data) => {
      setSqmData(data);
    });
    dataFetch(`${baseUrl}/sqm${urls.past_url}`).then((data) => {
      setSqmDataYes(data);
    });

    // NsmData
    dataFetch(`${baseUrl}/nsm${urls.recent_url}`).then((data) => {
      setNsmData(data);
    });
    dataFetch(`${baseUrl}/nsm${urls.past_url}`).then((data) => {
      setNsmDataYes(data);
    });

    // AlldylosData
    dataFetch(`${baseUrl}/dylos${urls.recent_url}`).then((data) => {
      setAlldylosData(data);
    });
    dataFetch(`${baseUrl}/dylos${urls.past_url}`).then((data) => {
      setAlldylosDataYes(data);
    });

    // SoloData
    dataFetch(`${baseUrl}/solo${urls.recent_url}`).then((data) => {
      setSoloData(data);
    });
    dataFetch(`${baseUrl}/solo${urls.past_url}`).then((data) => {
      setSoloDataYes(data);
    });

    dataFetch(`${baseUrl}/weather-safety/${localStorage.getItem('activeScope')}${urls.recent_url}`).then((data) => {
      setWeatherSafety(data);
    });

  }, []);

  useEffect(() => {
    const baseUrl = `${apiEndpoint}/data`;

    if (delay === true) {
      const interval = setInterval(() => {
        // sqmData
        dataFetch(`${baseUrl}/sqm${urls.recent_url}`).then((data) => {
          setSqmData(data);
        });
        dataFetch(`${baseUrl}/sqm${urls.past_url}`).then((data) => {
          setSqmDataYes(data);
        });

        // NSmData
        dataFetch(`${baseUrl}/nsm${urls.recent_url}`).then((data) => {
          setNsmData(data);
        });
        dataFetch(`${baseUrl}/nsm${urls.past_url}`).then((data) => {
          setNsmDataYes(data);
        });

        // AlldylosData
        dataFetch(`${baseUrl}/dylos${urls.recent_url}`).then((data) => {
          setAlldylosData(data);
        });
        dataFetch(`${baseUrl}/dylos${urls.past_url}`).then((data) => {
          setAlldylosDataYes(data);
        });

        // SoloData
        dataFetch(`${baseUrl}/solo${urls.recent_url}`).then((data) => {
          setSoloData(data);
        });
        dataFetch(`${baseUrl}/solo${urls.past_url}`).then((data) => {
          setSoloDataYes(data);
        });
        dataFetch(`${baseUrl}/weather-safety/${localStorage.getItem('activeScope')}${urls.recent_url}`).then((data) => {
          setWeatherSafety(data);
        });
      }, 120000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [delay, urls, settings.voyager_feed]);

  return (
    <>
      {sqmData.status === true &&
        sqmDataYes.status === true &&
        nsmData.status === true &&
        nsmDataYes.status === true &&
        soloData.status === true &&
        soloDataYes.status === true &&
        alldylosData.status === true &&
        alldylosData.status === true ? (
        <Spinner
          animation="grow"
          variant="info"
          style={{
            height: "200px",
            width: "200px",
          }}
        />
      ) : (
        <Row className="daily chart-wrapper full-height gx-5">
          <Col className="mb-1 twenty-five" sm={6}>
            {soloData.status === true ? (
              "Loading ..."
            ) : (
              <CloudChart
                settings={settings}
                options={options}
                cloud={soloData.items}
                cloudYes={soloDataYes.items}
                weatherSafety={weatherSafety.items}
                setDelay={setDelay}
              />
            )}
          </Col>
          <Col className="mb-1 twenty-five" sm={6}>
            {soloData.status === true ? (
              ""
            ) : (
              <RainChart
                settings={settings}
                options={options}
                rain={soloData.items}
                rainYes={soloDataYes.items}
                weatherSafety={weatherSafety.items}
              />
            )}
          </Col>
          <Col className="mb-1 twenty-five" sm={6}>
            {soloData.status === true ? (
              ""
            ) : (
              <WindChart
                settings={settings}
                options={options}
                wind={soloData.items}
                windYes={soloDataYes.items}
              />
            )}
          </Col>
          <Col className="mb-1 twenty-five" sm={6}>
            {soloData.status === true ? (
              ""
            ) : (
              <TempChart
                settings={settings}
                options={options}
                temps={soloData.items}
                tempsYes={soloDataYes.items}
              />
            )}
          </Col>

          <Col className="mb-1 twenty-five" sm={6}>
            <HFDStarIndexChart
              apiEndpoint={apiEndpoint}
              data={dataType}
            />
          </Col>

          <Col className="mb-1 twenty-five" sm={6}>
            <ChartDataWrapper
              settings={settings}
              options={options}
              sqm={sqmData.items}
              sqmYes={sqmDataYes.items}
              alldylos={alldylosData.items}
              alldylosYes={AlldylosDataYes.items}
              nsm={nsmData.items}
              nsmYes={nsmDataYes.items}
            />
          </Col>

          <Col className="mb-1 twenty-five" sm={6}>
            <GuidingChart
              apiEndpoint={apiEndpoint}
              data={dataType}
            />
          </Col>

          <Col className="mb-1 twenty-five" sm={6}>
            <Status apiEndpoint={apiEndpoint} voyager_feed={settings.voyager_feed} station_timezone={settings.station_timezone} />
          </Col>
        </Row>
      )}
    </>
  );
}

import momenttz from "moment-timezone";
import { before18hr, after6hr, from48, nowunix } from "./intervals";

export const OPTIONS = {
  chart: {
    id: "apexchart-example",
    stacked: false,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  legend: {
    show: true,
    labels: {
      colors: "#fff",
    },
    markers: {
      width: 7,
      height: 7,
    },
  },
  stroke: {
    width: 2,
  },
  tooltip: {
    x: {
      show: false,
    },
  },
  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  markers: {
    size: 0,
    hover: {
      size: 10,
    },
  },
  dataLabels: {
    enabled: false,
  },
  noData: {
    text: "No Data For Now",
    style: {
      color: "#A3A3A3",
      fontSize: "14px",
    },
  },
  colors: [],
  xaxis: {
    type: "datetime",
    min: before18hr,
    max: after6hr,
    axisBorder: {
      show: false,
    },
    tickAmount: 14,
    labels: {
      show: true,
      rotate: 0,
      style: {
        colors: "#A3A3A3",
        padding: "10px",
      },
      formatter(val) {
        return momenttz(new Date(val * 1000))
          .tz(
            `${
              JSON.parse(localStorage.getItem("settings")).default.data
                .station_timezone
            }`
          )
          .format("HH:mm");
      },
    },
  },
  yaxis: [
    {
      tickAmount: 4,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
      },
      labels: {
        style: {
          colors: "#A3A3A3",
        },
      },
    },
  ],
};
export const OPTIONS_WEEKLY = {
  ...OPTIONS,
  xaxis: {
    ...OPTIONS.xaxis,
    min: from48,
    max: nowunix,
    labels: {
      ...OPTIONS.xaxis.labels,
      rotate: 0,
      formatter(val) {
        return momenttz(new Date(val * 1000))
          .tz(
            `${
              JSON.parse(localStorage.getItem("settings")).default.data
                .station_timezone
            }`
          )
          .format("DD/MM HH:mm");
      },
    },
  },
};

export const nsmDaily = {
  options: {
    ...OPTIONS,

    colors: [
      "#A4A1FB",
      "#247BA0",
      "#A4A1FB55",
      "#247BA055",
      "#fb8500",
      "#d00000",
    ],
    legend: {
      show: false,
    },
    stroke: {
      ...OPTIONS.stroke,
      dashArray: [0, 0, 5, 5, 5, 5],
    },
    yaxis: [
      {
        ...OPTIONS.yaxis[0],
        labels: {
          ...OPTIONS.yaxis[0].labels,
        },
      },
    ],
  },
  series: [],
};

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import momenttz from "moment-timezone";
import { Clock } from "react-bootstrap-icons";

function TempChart({ temps, options, settings }) {
  const [RecentValue, setRecentValue] = useState({ value: 0, time: "" });
  const [RecentValueDew, setRecentValueDew] = useState(0);
  const temp = [];
  const dewPoint = [];
  if (temps.data !== undefined) {
    temps.data.forEach((item) => {
      temp.push({ x: parseInt(item.readingDate, 10), y: item.temp });
      dewPoint.push({ x: parseInt(item.readingDate, 10), y: item.dewPoint });
    });
  }
  const [apex, setApex] = useState({
    options: {
      ...options,
      colors: ["#46A8DF", "#F6AE2D"],
      xaxis: {
        ...options.xaxis,
        tickAmount: 16,
      },
      yaxis: [
        {
          ...options.yaxis[0],
          title: {
            text: "Temperature/ Dew Point",
            style: {
              fontSize: "12px",
            },
          },
          labels: {
            ...options.yaxis[0].labels,
            formatter(val) {
              return val.toFixed(1);
            },
          },
        },
      ],
    },
    series: [
      {
        data: [],
      },
      {
        data: [],
      },
    ],
  });

  let indicator;
  const duration = parseInt(
    moment
      .duration(
        moment(new Date(), "HH:mm:ss a").diff(
          moment(RecentValue.time, "HH:mm:ss a")
        )
      )
      .asMinutes() % 60,
    10
  );
  if (duration > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__temp">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  } else if (
    parseInt(RecentValue.value, 10) - parseInt(RecentValueDew, 10 < 5)
  ) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__temp">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <status-indicator active negative pulse style={{ margin: "0 10px" }} />
      </OverlayTrigger>
    );
  } else {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__temp">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <status-indicator active positive pulse style={{ margin: "0 10px" }} />
      </OverlayTrigger>
    );
  }

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        { name: "Temperature", data: temp },
        { name: "Dew point", data: dewPoint },
      ],
    });

    setRecentValue(
      temp[0] === undefined
        ? { value: "", time: "" }
        : {
            value: temp[temp.length - 1].y,
            time: momenttz(new Date(temp[temp.length - 1].x * 1000))
              .tz(`${settings.station_timezone}`)
              .format("HH:mm"),
          }
    );
    setRecentValueDew(
      dewPoint[0] === undefined ? "" : dewPoint[dewPoint.length - 1].y
    );
  }, [temps, options]);
  return (
    <div className="pt-0 px-0 full-height custom-card">
      <div className="recent-value">
        <h6 className="custom-card__title">
          <span>
            <span className="value">
              {RecentValue.value !== ""
                ? parseFloat(RecentValue.value).toFixed(1)
                : "_"}
            </span>{" "}
            &#176;C /{" "}
            <span className="value">
              {RecentValueDew !== ""
                ? parseFloat(RecentValueDew).toFixed(1)
                : "_"}
            </span>
            &#176;C
          </span>
        </h6>
      </div>
      <div className="full-height">
        <Chart
          id="temp-chart"
          options={apex.options}
          series={apex.series}
          type="line"
          width="95%"
          height="250"
        />
      </div>
    </div>
  );
}

TempChart.propTypes = {
  temps: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
  }).isRequired,
  settings: PropTypes.shape({
    station_timezone: PropTypes.string,
  }).isRequired,
};

export default TempChart;

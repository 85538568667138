import React, { useEffect, useState } from 'react';
import { Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import SqmChart from './SqmChart';
import AlldylosChart from './AlldylosChart';
import NsmChart from './NsmChart';

const ChartDataWrapper = ({ options, sqm, sqmYes, settings, alldylos, alldylosYes, nsm, nsmYes }: any) => {
  const [activeChart, setActiveChart] = useState('sqm');
  const [sqmHeader, setSqmHeader] = useState();
  const [seeingHeader, setSeeingHeader] = useState();
  const [dustHeader, setDustHeader] = useState();
  const [selected, setSelected] = useState(
    <SqmChart
      options={options}
      sqm={sqm}
      sqmYes={sqmYes}
      settings={settings}
      setSqmHeader={setSqmHeader}
      showSqmHeader
    />
  );

  useEffect(() => {
    switch (activeChart) {
      case 'sqm':
      default:
        setSelected(
          <SqmChart
            options={options}
            sqm={sqm}
            sqmYes={sqmYes}
            settings={settings}
            setSqmHeader={setSqmHeader}
            showSqmHeader
          />
        );
        break;
      case 'seeing':
        setSelected(
          <>
            <AlldylosChart
              alldylos={alldylos}
              alldylosYes={alldylosYes}
              options={options}
              settings={settings}
              setSeeingHeader={setSeeingHeader}
              showSeeingHeader
            />
            <div style={{ color: "white", fontSize: ".6rem" }}>
              Particulate density &mu;g/m<sup>3</sup>
            </div>
          </>
        );
        break;
      case 'dust':
        setSelected(
          <>
            <NsmChart
              options={options}
              nsm={nsm}
              nsmYes={nsmYes}
              settings={settings}
              setDustHeader={setDustHeader}
              showDustHeader
            />
            <div style={{ color: "white", fontSize: "0.6rem" }}>
              Long Exposure Seeing in ArcSec
            </div>
          </>
        );
        break;
    }
  }, [activeChart, alldylos, alldylosYes, nsm, nsmYes, options, settings, sqm, sqmYes]);

  return (
    <Card className="pt-1 pb-2 px-1 full-height">
      <div className="sqm-seeing-dust-chart-wrapper side-by-side" style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "40%" }}>
          <button
            type="button"
            className={`underline-on-hover ${activeChart === 'sqm' && 'active'}`}
            onClick={() => setActiveChart('sqm')}
          >
            SQM
          </button>
          &nbsp;&nbsp;
          <button
            type="button"
            className={`underline-on-hover ${activeChart === 'seeing' && 'active'}`}
            onClick={() => setActiveChart('seeing')}
          >
            Dust
          </button>
          &nbsp;&nbsp;
          <button
            type="button"
            className={`underline-on-hover ${activeChart === 'dust' && 'active'}`}
            onClick={() => setActiveChart('dust')}
          >
            Seeing
          </button>
        </div>
        {/* <select onChange={(e) => setActiveChart(e.target.value)}>
          <option value="sqm">SQM</option>
          <option value="seeing">Dust</option>
          <option value="dust">Seeing</option>
        </select> */}

        <div>
          {activeChart === 'sqm' && <h6>{sqmHeader}</h6>}
          {activeChart === 'seeing' && <h6>{seeingHeader}</h6>}
          {activeChart === 'dust' && <h6>{dustHeader}</h6>}
        </div>
      </div>

      <div className="chart-contianer" style={{ marginTop: "-12px" }}>
        {selected}
      </div>
    </Card>

  );
};

export default ChartDataWrapper;

import React, { ReactElement, useState } from "react";
import { Container, Row, Toast } from "react-bootstrap";
import WeeklyChartWapper from "./shared/chartsWeekly/WeeklyChartWapper";
import { OPTIONS_WEEKLY } from "./shared/options";
import { URLsWeekly } from "./shared/intervals";
import Footer from "./Footer";
import { SettingsContext } from "../hooks/settings-context";

export default function Telemetrics(): ReactElement {
  const [show, setShow] = useState({
    status: false,
    data: {},
    error: null,
    message: "",
  });
  return (
    <SettingsContext.Consumer>
      {({ apiEndpoint }) => (
        <Container fluid>
          <Row className="custom__row">
            <Toast
              style={{
                position: "absolute",
                zIndex: 1,
                right: 0,
              }}
              onClose={() =>
                setShow({ status: false, data: {}, error: null, message: "" })
              }
              show={show.status}
              delay={60000}
              autohide
            >
              <Toast.Header>
                <strong className="mr-auto">Scheduler</strong>
              </Toast.Header>
              <Toast.Body>{show.message}</Toast.Body>
            </Toast>
            <WeeklyChartWapper
              apiEndpoint={apiEndpoint}
              OPTIONS_WEEKLY={OPTIONS_WEEKLY}
              urls={URLsWeekly}
              setShow={setShow}
            />
          </Row>
          <Footer />
        </Container>
      )}
    </SettingsContext.Consumer>
  );
}

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Table } from "react-bootstrap";
import moment from "moment";
import {
  Reception4,
  Reception3,
  Reception2,
  Reception1,
} from "react-bootstrap-icons";
import dataFetch from "../../actions/fetchDataActions";

interface Props extends RouteComponentProps {
  apiEndpoint: string;
  maxHeight: string;
}

const UpcomingDataTable: FC<Props> = ({ apiEndpoint, maxHeight }: Props) => {
  const [TableData, setTableData] = useState<{
    error: null;
    status: boolean;
    items: {
      data: {
        passes: {
          startUTC: number;
          maxUTC: number;
          startAz: string;
          startEl: string;
          startAzCompass: string;
          endAzCompass: string;
          maxAz: string;
          maxAzCompass: string;
          maxEl: string;
          endUTC: number;
          endAz: string;
          endEl: string;
          mag: number;
          duration: number;
        }[];
      };
    };
  }>({
    error: null,
    status: false,
    items: {
      data: {
        passes: [
          {
            startUTC: 0,
            maxUTC: 0,
            startAz: "",
            startEl: "",
            startAzCompass: "",
            endAzCompass: "",
            maxAz: "",
            maxAzCompass: "",
            maxEl: "",
            endUTC: 0,
            endAz: "",
            endEl: "",
            mag: 0,
            duration: 0,
          },
        ],
      },
    },
  });
  const upcomingDataFetch = () => {
    dataFetch(`${apiEndpoint}/data/iss/passes`)
      .then((data) => {
        if (data.error === null) {
          setTableData({
            ...TableData,
            status: true,
            items: {
              ...data.items,
            },
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getIcon = (mag: number) => {
    if (mag < -3) {
      return <Reception4 className="status__indicator" color="green" />;
    }
    if (mag < -2) {
      return <Reception3 className="status__indicator" color="green" />;
    }
    if (mag < -1) {
      return <Reception2 className="status__indicator" color="orange" />;
    }
    if (mag > -1) {
      return <Reception1 className="status__indicator" color="red" />;
    }
    return "";
  };

  const getElevationIcon = (el: string | number) => {
    if (el >= 40) {
      return <Reception4 className="status__indicator" color="green" />;
    }
    if (el >= 20 && el < 40) {
      return <Reception2 className="status__indicator" color="orange" />;
    }
    if (el < 20) {
      return <Reception1 className="status__indicator" color="red" />;
    }
    return "";
  };
  useEffect(() => {
    upcomingDataFetch();
    console.log(TableData);
  }, [TableData.status]);

  return (
    <div style={{ overflow: "scroll", maxHeight: `${maxHeight}` }}>
      <Table hover bsPrefix="custom-table">
        <thead>
          <tr>
            <th>Next ISS passes</th>
            <th>Start</th>
            <th>Direction</th>
            <th>Elevation</th>
            <th>Max Altitude</th>
            <th>Direction</th>
            <th>Elevation</th>
            <th>End</th>
            <th>Direction</th>
            <th>Elevation</th>
            <th> </th>
            <th>Magnitude</th>
            <th> </th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {TableData?.status === true &&
          TableData?.items.data.passes !== undefined ? (
            TableData?.items.data.passes.map((row) => {
              return (
                <tr>
                  <td>{moment(row.startUTC * 1000).format("DD MMMM")}</td>
                  <td>{moment(row.startUTC * 1000).format("HH:mm")}</td>
                  <td>
                    {row.startAz}&deg; {row.startAzCompass}
                  </td>
                  <td>{row.startEl}</td>
                  <td>{moment(row.maxUTC * 1000).format("HH:mm")}</td>
                  <td>
                    {row.maxAz}&deg; {row.maxAzCompass}
                  </td>
                  <td>{row.maxEl}</td>
                  <td>{moment(row.endUTC * 1000).format("HH:mm")}</td>
                  <td>
                    {row.endAz}&deg; {row.endAzCompass}
                  </td>
                  <td>{row.endEl}</td>
                  <td>{getElevationIcon(row.maxEl)}</td>
                  <td>{row.mag}</td>
                  <td>{getIcon(row.mag)}</td>
                  <td>
                    {new Date(row.duration * 1000).toISOString().substr(11, 8)}
                  </td>
                </tr>
              );
            })
          ) : (
            <p>No Data Now</p>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default withRouter(UpcomingDataTable);

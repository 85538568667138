import React, { useState, FunctionComponent } from "react";
import { Card, Form } from "react-bootstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";

type AppProps = {
  data: {
    widget: string;
    src: string;
    style: {
      width: string;
      height?: string;
    };
  };
  settings: {
    cctv_stream_1: string;
    cctv_stream_2: string;
  };
};

const WidgetCardSmall: FunctionComponent<AppProps & RouteComponentProps> = (
  props: AppProps & RouteComponentProps
) => {
  const [changeCctv, handleChangeCctv] = useState(true);

  const handleChange = () => {
    handleChangeCctv(!changeCctv);
  };
  const { data, match, settings } = props;
  if (changeCctv === true && data.widget === "CCTV") {
    data.src = `${settings.cctv_stream_1}`;
  }
  if (changeCctv === false && data.widget === "CCTV") {
    data.src = `${settings.cctv_stream_2}`;
  }

  return (
    <Card className="pb-2 pt-2 px-1 full-height custom-card">
      <span style={{ display: "flex", justifyContent: "space-between" }}>
        {data.widget === "CCTV" ? (
          <>
            <div style={{ display: "flex", alignContent: "center" }}>
              <h6
                className="custom-card__title"
                style={{
                  lineHeight: "28px",
                  margin: "0",
                }}
              >
                CCTV 1
              </h6>
              <Form>
                <Form.Check
                  onChange={handleChange}
                  type="switch"
                  className="custom-switch__cctv"
                />
              </Form>
              <h6
                className="custom-card__title"
                style={{
                  lineHeight: "28px",
                  margin: "0",
                }}
              >
                CCTV 2
              </h6>
            </div>
            {match.path === "/" ? (
              <a href="/cctv" className="custom-btn__circular plus">
                {" "}
              </a>
            ) : (
              ""
            )}
          </>
        ) : (
          <h6>
            {data.widget}
            {match.path === "/" ? (
              <a href="/windy" className="custom-btn__circular plus">
                {" "}
              </a>
            ) : (
              ""
            )}
          </h6>
        )}
      </span>
      <iframe
        title="metroblue"
        src={data.src}
        frameBorder="0"
        scrolling="NO"
        sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
        style={data.style}
      />
    </Card>
  );
};

export default withRouter(WidgetCardSmall);

import React from "react";

export default function usePersistedSettings(
  key: string,
  defaultValue: {
    default: {
      apiEndpoint: string;
      id: string;
      name: string;
      data: {
        app_layout: string;
        app_logo: string;
        app_name: string;
        cctv_stream_1: string;
        cctv_stream_2: string;
        forecast_widget: string;
        statelite_1_widget: string;
        statelite_2_widget: string;
        statelite_3_widget: string;
        station_lat: string;
        station_long: string;
        station_timezone: string;
        weather_station: string;
        allsky_asset_directory: string;
        allsky_last_image: string;
        allSky_last_video: string;
        voyager_feed: string;
        voyager_feeds: Array<string>;
        scopes: string;
      };
    };
  }
) {
  const [state, setState] = React.useState(() => {
    const persistedState = localStorage.getItem(key);
    return persistedState ? JSON.parse(persistedState) : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);
  return [state, setState];
}

import React, { ReactElement, useState, useEffect } from "react";

import { Container, Row, Col, Card, Image } from "react-bootstrap";
import Lightbox from "react-awesome-lightbox";
import { ZoomIn } from "react-bootstrap-icons";
import "react-awesome-lightbox/build/style.css";
import Footer from "./Footer";
import MoonFeatures from "../assets/moon-features.png";
import { SettingsContext } from "../hooks/settings-context";

export default function Lunar(): ReactElement {
  const [show, setShow] = useState(false);
  useEffect(() => { }, [show]);
  return (
    <SettingsContext.Consumer>
      {({ data }) => (
        <>
          <Container fluid>
            <Row
              className="mx-1 custom__row gy-2"
              style={{
                color: "white",
                height: "calc(100vh - 140px)",
              }}
            >
              <Col md={6} className="full-height">
                <Col md={12} style={{ height: "40%" }}>
                  <Card className="pb-2 pt-2 px-2 full-height">
                    <>
                      <h6>Lunar Phase and Libration</h6>
                    </>
                    <div style={{ display: `${show === true ? "" : "none"}` }}>
                      <Lightbox
                        image="https://assets.obsmoana.com/venus/assets/lunar-phase-latest.png"
                        title="Libration"
                        onClose={() => {
                          setShow(false);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        height: "95%",
                        margin: "auto",
                      }}
                    >
                      <Image
                        src="https://assets.obsmoana.com/venus/assets/lunar-phase-thumbnail.png"
                        style={{ maxWidth: "100%", maxHeight: "95%" }}
                        onClick={() => {
                          setShow(true);
                        }}
                      />
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                        }}
                      >
                        <ZoomIn
                          onClick={() => {
                            setShow(true);
                          }}
                        />
                      </span>
                    </div>
                  </Card>
                </Col>
                <Col md={12} className="mt-1" style={{ height: "60%" }}>
                  <Card className="pb-2 pt-2 px-2 full-height">
                    <span>
                      <h6>Lunar Landing Sites</h6>
                    </span>
                    <iframe
                      title="panel3"
                      src="https://www.arcgis.com/apps/Styler/index.html?appid=eb8382bfcf0b4d7885f3e39dc0e49e2e"
                      className="full-height"
                    />
                  </Card>
                </Col>
              </Col>
              <Col md={6} className="full-height">
                <Card className="pt-2 full-height">
                  <Card.Body className="p-0">
                    <Card.Title className="h6 px-2">Lunar Map</Card.Title>
                  </Card.Body>
                  <Image
                    src={MoonFeatures}
                    style={{
                      height: "95%",
                      margin: "auto",
                    }}
                  />
                  <small style={{ textAlign: "center", padding: "8px" }}>
                    Image from the planet Society
                  </small>
                </Card>
              </Col>
            </Row>
          </Container>
          <Footer />
        </>
      )}
    </SettingsContext.Consumer>
  );
}

/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { Card, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Power, Clock } from "react-bootstrap-icons";
import moment from "moment";
import momenttz from "moment-timezone";
// import usb from "../../../assets/usb.svg";
import usbPositive from "../../../assets/usb1Positive.svg";
import usb3Negative from "../../../assets/usb3Negative.svg";
import usb3Positive from "../../../assets/usb3Positive.svg";
import usbNegative from "../../../assets/usb1Negative.svg";
import "status-indicator/styles.css";

import dataFetch from "../../../actions/fetchDataActions";


type StatusProps = {
  apiEndpoint: string,
  voyager_feed: string,
  station_timezone: string,
};

export default function Status({ apiEndpoint, voyager_feed, station_timezone }: StatusProps) {
  const [statusData, setSatusData] = useState(null);
  const [voyagerData, setVoyagerData] = useState({});
  const [delay, setDelay] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('voyager_feed');

  const Powerpositve = (lastUpdated) => (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id="button-tooltip__cloud-1">
          Last updated {moment.unix(lastUpdated).utc().format("DD-MMM HH:mm:ssa")}
        </Tooltip>
      }
    >
      <Power
        style={{
          color: "#00FF07",
          margin: "0 10px",
        }}
      />
    </OverlayTrigger>
  );
  const PowerNegative = (
    <Power
      style={{
        color: "#E8442E",
        margin: "0 5px",
      }}
    />
  );
  const PowerImtermediary = (
    <Power
      style={{
        color: "#F6FF00",
        margin: "0 5px",
      }}
    />
  );
  const usb3green = (
    <img
      src={usb3Positive}
      alt="usb"
      style={{
        margin: "0 5px",
        width: "30px",
        padding: "2px",
      }}
    />
  );
  const usb3red = (
    <img
      src={usb3Negative}
      alt="usb"
      style={{
        margin: "0 10px",
        width: "30px",
        padding: "2px",
      }}
    />
  );
  const usbgreen = (
    <img
      src={usbPositive}
      alt="usb"
      style={{
        margin: "0 5px",
        width: "26px",
        padding: "2px",
      }}
    />
  );
  const usbred = (
    <img
      src={usbNegative}
      alt="usb"
      style={{
        margin: "0 10px",
        width: "26px",
        padding: "2px",
      }}
    />
  );
  const indicatorNegative = (
    <status-indicator negative style={{ margin: "0 10px" }} />
  );
  const indicatorPositive = (
    <status-indicator positive style={{ margin: "0 10px" }} />
  );
  const indicatorIntermediary = (
    <status-indicator intermediary style={{ margin: "0 10px" }} />
  );

  const positivePulseIndicator = (lastUpdated) => (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id="button-tooltip__cloud-1">
          Last updated {moment.unix(lastUpdated).tz(station_timezone).format("DD-MMM HH:mm:ssa")}
        </Tooltip>
      }
    >
      <status-indicator positive active pulse style={{ margin: "0 10px" }} />
    </OverlayTrigger>
  );

  const negativePulseIndicator = (lastUpdated) => (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id="button-tooltip__cloud-1">
          Last updated {moment.unix(lastUpdated).tz(station_timezone).format("DD-MMM HH:mm:ssa")}
        </Tooltip>
      }
    >
      <status-indicator negative active pulse style={{ margin: "0 10px" }} />
    </OverlayTrigger>
  );

  const clockIndicator = (lastUpdated) => {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__cloud-1">
            Last updated {moment.unix(lastUpdated).tz(station_timezone).format("DD-MMM HH:mm:ssa")}
          </Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  }

  const isStaleData = (readingDate, stalePeriod, tag = '') => {
    const lastReading = moment.unix(readingDate).tz(station_timezone).format("DD/MM/YY HH:mm:ss a").toString();
    const currentTime = moment(new Date()).tz(station_timezone).format("DD/MM/YY HH:mm:ss a").toString();
    const duration = moment.duration(
      moment(currentTime, "DD/MM/YY HH:mm:ss a")
        .diff(moment(lastReading, "DD/MM/YY HH:mm:ss a"))
    ).asMinutes();

    return (Math.floor(duration) > stalePeriod);
  }
  useEffect(() => {
    if (voyager_feed === null) {
      return;
    }

    const statusDataFetch = () => {
      dataFetch(`${apiEndpoint}/status`)
        .then((data) => {
          if (data.error === null) {
            setSatusData({
              ...data.items.data,
            });
          }
        })
        .catch((e) => {
          /* eslint-disable no-console */
          console.log(e);
          /* eslint-enable no-console */
        });
      setDelay(true);
    };

    const voyagerDataFetch = (scope) => {
      dataFetch(`${apiEndpoint}/data/voyager/${scope}`)
        .then((data) => {
          if (data.error === null) {
            setVoyagerData(data.items.data);
          }
        })
        .catch((e) => {
          console.log(e);
        })
      setDelay(true);
    }

    if (delay === true) {
      const interval = setInterval(() => {
        statusDataFetch();
        voyagerDataFetch(voyager_feed)
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
    statusDataFetch();
    voyagerDataFetch(voyager_feed);
  }, [delay, statusData, apiEndpoint, voyager_feed]);

  return (
    <Card className="pb-3 px-2 pt-1 full-height">
      <Card.Body className="p-0">
        <Card.Title
          className="h6 chart-card__header-alt"
          style={{ marginBottom: "14px" }}
        >
          <div className="separate-with-border-left">
            <button type="button" className={`underline-on-hover ${selectedStatus === 'status' && 'active'}`} onClick={() => setSelectedStatus('status')}>USB/Power</button>
          </div>
          <button type="button" className={`underline-on-hover ${selectedStatus === 'voyager_feed' && 'active'}`} onClick={() => setSelectedStatus('voyager_feed')}>
            {voyager_feed}
          </button>
        </Card.Title>
      </Card.Body>
      {
        selectedStatus === 'status' ?
          <div className="status_table">
            {statusData && (
              <Table
                bordered
                bsPrefix="custom-table__status"
                style={{ width: "100%" }}
              >
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#14152555", padding: "0 10px" }}>
                      <tr>
                        <td>Weather/Roof</td>
                        <td>
                          <div className="sensor-status">
                            {statusData["7"] === undefined
                              ? "-"
                              : statusData["7"] === true
                                ? indicatorPositive
                                : indicatorNegative}
                            {statusData["7"] === undefined
                              ? "-"
                              : statusData["7"] === true
                                ? indicatorPositive
                                : indicatorNegative}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Mount</td>
                        <td>
                          <div className="sensor-status">
                            {statusData["3"] === undefined
                              ? "-"
                              : statusData["3"] === true
                                ? Powerpositve
                                : PowerNegative}
                            <td>
                              {statusData["32"] === true ? usb3green : usb3red}
                            </td>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Perseus</td>
                        <td>
                          <div className="sensor-status">
                            {statusData["3"] === undefined
                              ? "-"
                              : statusData["3"] === true
                                ? Powerpositve
                                : PowerNegative}
                            <td>
                              {statusData["32"] === true ? usb3green : usb3red}
                            </td>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>CDK Dew</td>

                        <td>
                          <div className="sensor-status">
                            {statusData["4"] === undefined
                              ? "-"
                              : statusData["4"] === true
                                ? Powerpositve
                                : PowerNegative}
                            <td>
                              {statusData["32"] === true ? usb3green : usb3red}
                            </td>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>CDK focus</td>

                        <td>
                          <div className="sensor-status">
                            {statusData["4"] === undefined
                              ? "-"
                              : statusData["4"] === true
                                ? Powerpositve
                                : PowerNegative}
                            <td>
                              {statusData["32"] === true ? usb3green : usb3red}
                            </td>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>CDK shutters</td>

                        <td>
                          <div className="sensor-status">
                            {statusData["4"] === undefined
                              ? "-"
                              : statusData["4"] === true
                                ? Powerpositve
                                : PowerNegative}
                            <td>
                              {statusData["32"] === true ? usb3green : usb3red}
                            </td>
                          </div>
                        </td>
                      </tr>
                    </td>
                    <td style={{ backgroundColor: "#14152555", padding: "0 10px" }}>
                      <tr>
                        <td>eShel Cal</td>
                        <td>
                          {statusData["29"] === true ? Powerpositve : PowerNegative}
                        </td>
                        <td>{statusData["37"] === true ? usb3green : usb3red}</td>
                      </tr>
                      <tr>
                        <td>eShel Science</td>
                        <td>
                          {statusData["29"] === true ? Powerpositve : PowerNegative}
                        </td>
                        <td>{statusData["37"] === true ? usb3green : usb3red}</td>
                      </tr>
                      <tr>
                        <td>eShel Guide</td>
                        <td>
                          {statusData["29"] === true ? Powerpositve : PowerNegative}
                        </td>
                        <td>{statusData["37"] === true ? usb3green : usb3red}</td>
                      </tr>
                      <tr>
                        <td>Planet Cam</td>
                        <td>
                          {statusData["29"] === true ? Powerpositve : PowerNegative}
                        </td>
                        <td>{statusData["37"] === true ? usb3green : usb3red}</td>
                      </tr>
                      <tr>
                        <td>Planet CFW</td>
                        <td>
                          {statusData["29"] === true ? Powerpositve : PowerNegative}
                        </td>
                        <td>{statusData["37"] === true ? usb3green : usb3red}</td>
                      </tr>
                      <tr>
                        <td>USB Hub</td>
                        <td>
                          {statusData["29"] === true ? Powerpositve : PowerNegative}
                        </td>
                        <td>{statusData["37"] === true ? usb3green : usb3red}</td>
                      </tr>
                    </td>
                    <td style={{ backgroundColor: "#14152555", padding: "0 10px" }}>
                      <tr>
                        <td>Andor iKon</td>
                        <td>
                          {statusData["28"] === true ? Powerpositve : PowerNegative}
                        </td>
                        <td>{statusData["36"] === true ? usbgreen : usbred}</td>
                      </tr>
                      <tr>
                        <td>IKon Filter</td>
                        <td>
                          {statusData["29"] === true ? Powerpositve : PowerNegative}
                        </td>
                        <td>{statusData["37"] === true ? usbgreen : usbred}</td>
                      </tr>
                      <tr>
                        <td>IKon Guide N/S</td>
                        <td>
                          {statusData["30"] === true ? Powerpositve : PowerNegative}
                        </td>
                        <td>{statusData["38"] === true ? usb3green : usb3red}</td>
                      </tr>
                      <tr>
                        <td>Port 3 camera</td>
                        <td>
                          {statusData["39"] === undefined
                            ? ""
                            : statusData["39"] === true
                              ? Powerpositve
                              : PowerNegative}
                        </td>
                        <td>{statusData["31"] === true ? usb3green : usb3red}</td>
                      </tr>
                      <tr>
                        <td>Port 3 filter</td>
                        <td>&nbsp;</td>
                        <td>{statusData["32"] === true ? usbgreen : usbred}</td>
                      </tr>
                      <tr>
                        <td>Port 3 Guide N/S</td>
                        <td>{statusData["32"] === true ? usb3green : usb3red}</td>
                        <td>{statusData["32"] === true ? usbred : usb3red}</td>
                      </tr>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
          : (
            <div className="status_table">
              {voyagerData && (
                <Table
                  bordered
                  bsPrefix="custom-table__status"
                  style={{ width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "#14152555", padding: "0 10px", fontSize: "0.8rem" }}>
                        <tr>
                          <td>Voyager</td>
                          {isStaleData(voyagerData?.roof?.timestamp, 10, 'Control Data') ?
                            (<td>{
                              clockIndicator(voyagerData?.roof?.timestamp)}</td>)
                            :
                            (<td>{voyagerData?.voyager?.a === true ?
                              positivePulseIndicator(voyagerData?.roof?.timestamp) :
                              negativePulseIndicator(voyagerData?.roof?.timestamp)
                            }
                            </td>)
                          }
                          <td>{voyagerData?.voyager?.b}</td>
                        </tr>
                        <tr>
                          <td>Roof/Safety</td>
                          {isStaleData(voyagerData?.roof?.timestamp, 10, 'Weather and Safety') ?
                            (<td>{clockIndicator(voyagerData?.roof?.timestamp)}</td>)
                            :
                            <td>{
                              voyagerData?.roof?.a === true ?
                                positivePulseIndicator(voyagerData?.roof?.timestamp) :
                                negativePulseIndicator(voyagerData?.roof?.timestamp)
                            }
                            </td>
                          }
                          <td>{voyagerData?.roof?.b}</td>
                        </tr>
                        <tr>
                          <td>Mount</td>
                          {isStaleData(voyagerData?.roof?.timestamp, 10, 'Control Data') ?
                            (<td>{clockIndicator(voyagerData?.roof?.timestamp)}</td>)
                            :
                            (<td>{
                              voyagerData?.mount?.a === true ?
                                positivePulseIndicator(voyagerData?.roof?.timestamp) :
                                negativePulseIndicator(voyagerData?.roof?.timestamp)
                            }
                            </td>)
                          }
                          <td>{voyagerData?.mount?.b}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;RA</td>
                          <td>&nbsp;</td>
                          <td>{voyagerData?.ra?.b}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;Dec</td>
                          <td>&nbsp;</td>
                          <td>{voyagerData?.dec?.b}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;Alt</td>
                          <td>&nbsp;</td>
                          <td>{voyagerData?.alt?.b}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;Az</td>
                          <td>&nbsp;</td>
                          <td>{voyagerData?.az?.b}</td>
                        </tr>
                        <tr>
                          <td>Camera</td>
                          {isStaleData(voyagerData?.roof?.timestamp, 10, 'Control Data') ?
                            (<td>{clockIndicator(voyagerData?.roof?.timestamp)}</td>)
                            :
                            <td>{
                              voyagerData?.camera?.a === true ?
                                positivePulseIndicator(voyagerData?.roof?.timestamp) :
                                negativePulseIndicator(voyagerData?.roof?.timestamp)
                            }
                            </td>
                          }
                          <td>{voyagerData?.camera?.b}</td>
                        </tr>
                        <tr>
                          <td>Guiding</td>
                          {isStaleData(voyagerData?.roof?.timestamp, 10, 'Control Data') ?
                            (<td>{clockIndicator(voyagerData?.roof?.timestamp)}</td>)
                            :
                            <td>{
                              voyagerData?.guiding?.a === true ?
                                positivePulseIndicator(voyagerData?.roof?.timestamp) :
                                negativePulseIndicator(voyagerData?.roof?.timestamp)
                            }
                            </td>
                          }
                          <td>{voyagerData?.guiding?.b}</td>
                        </tr>
                        <tr>
                          <td>Focuser</td>
                          {isStaleData(voyagerData?.roof?.timestamp, 10, 'Control Data') ?
                            (<td>{clockIndicator(voyagerData?.roof?.timestamp)}</td>)
                            :
                            <td>{
                              voyagerData?.focuser?.a === true ?
                                positivePulseIndicator(voyagerData?.roof?.timestamp) :
                                negativePulseIndicator(voyagerData?.roof?.timestamp)
                            }
                            </td>
                          }
                          <td>{voyagerData?.focuser?.b}</td>
                        </tr>
                      </td>

                      <td style={{ backgroundColor: "#14152555", padding: "0 10px", fontSize: "0.8rem" }} className="table-data-2">
                        <tr>
                          <td>Last FIT</td>
                          <td> UTC {moment(voyagerData?.lastFIT?.timestamp * 1000).utc().format("DD-MMM HH:mm")} /{voyagerData?.lastFIT?.a}</td>
                        </tr>
                        <tr>
                          <td>Last Science</td>
                          <td> UTC {moment(voyagerData?.lastScience?.timestamp * 1000 || Date.now()).utc().format("DD-MMM HH:mm")}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;Target</td>
                          <td>{voyagerData?.target?.a}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;Filter / Bin</td>
                          <td>Filter {voyagerData?.filter?.a} / Bin {voyagerData?.bin?.a}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;Exposure</td>
                          <td>{parseFloat(voyagerData?.exposure?.a).toFixed(1) || "-- "} secs</td>
                        </tr>
                        <tr>
                          <td>CCD Temp/Power</td>
                          <td>{voyagerData?.ccdTemp?.b}&deg; / {voyagerData?.ccdPower?.a}%</td>
                        </tr>
                        <tr>
                          <td>Sensor/FoV</td>
                          <td>{voyagerData?.sensor?.a} x {voyagerData?.sensor?.b} / {voyagerData?.FoV?.a} deg</td>
                        </tr>
                        <tr>
                          <td>Pixel Size/Scale</td>
                          <td>{voyagerData?.pixelSize?.a}&micro;m / {voyagerData?.imageScale?.a} arcsec</td>
                        </tr>
                        <tr>
                          <td>Sequence Progress</td>
                          <td>{voyagerData?.sequenceProgress?.a}</td>
                        </tr>
                        <tr>
                          <td>Shot Progress</td>
                          <td>{voyagerData?.shotProgress?.a}</td>
                        </tr>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </div>
          )
      }
    </Card>
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Clock, Moon } from "react-bootstrap-icons";

import moment from "moment";
import momenttz from "moment-timezone";

export default function SqmChart({ options, sqm, sqmYes, settings, setSqmHeader, showSqmHeader }) {

  const [apex, setApex] = useState({
    options: {
      ...options,
      chart: {
        stacked: false,
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#F6AE2D55", "#F6AE2D", "#4B70FF"],
      markers: {
        show: false,
      },
      legend: {
        show: true,
        labels: {
          colors: "#fff",
        },
        markers: {
          width: 7,
          height: 7,
        },
      },
      yaxis: [
        {
          ...options.yaxis[0],
          seriesName: "Yesterday",
          min: 0,
          max: 22,
          formatter(val) {
            return val.toFixed(0);
          },
          show: false,
        },
        {
          ...options.yaxis[0],
          seriesName: "mpsas",
          min: 0,
          max: 22,
          labels: {
            ...options.yaxis[0].labels,
          },
        },
        {
          ...options.yaxis[0],
          seriesName: "lunar",
          opposite: true,
          max: 90,
          min: 0,
          tickAmount: 6,
          labels: {
            ...options.yaxis[0].labels,
            formatter(val) {
              return parseFloat(val).toFixed(0);
            },
          },
        },
      ],
    },
    series: [
      {
        name: "Mag/Sq ArcSec",
        data: [],
      },
      {
        name: "Moon altitude",
        data: [],
      },
    ],
  });

  const [RecentValue, setRecentValue] = useState({ value: 0, time: "" });
  const [RecentValueLunar, setRecentValueLunar] = useState(0);
  const [MoonPhase, setMoonPhase] = useState("");

  let indicator;
  const duration = parseInt(
    moment
      .duration(
        moment(new Date(), "HH:mm:ss a").diff(
          moment(RecentValue.time, "HH:mm:ss a")
        )
      )
      .asMinutes() % 60,
    10
  );
  if (duration > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip">Last updated {RecentValue.time}</Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  } else {
    if (parseInt(RecentValue.value, 10) < 7) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__sqm">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            negative
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (
      parseInt(RecentValue.value, 10) >= 7 &&
      parseInt(RecentValue.value, 10) <= 15
    ) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__sqm">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            intermediary
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (parseInt(RecentValue.value, 10) > 15) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__sqm">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            positive
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
  }

  const [lunarAlt, setLunarAlt] = useState([]);
  const [mpsas, setMpsas] = useState([]);
  const [lunarAltYes, setLunarAltYes] = useState([]);
  const [mpsasYes, setMpsasYes] = useState([]);

  useEffect(() => {
    if (sqm.data === undefined) return;
    if (sqmYes.data === undefined) return;

    const mpsasCoords = [];
    const lunarAltCoords = [];
    const mpsasYesCoords = [];
    const lunarAltYesCoords = [];

    sqm.data.forEach((item) => {
      mpsasCoords.push({
        x: parseInt(item.readingDate, 10),
        y: parseInt(item.mpsas, 10),
      });
      lunarAltCoords.push({ x: parseInt(item.readingDate, 10), y: item.lunarAlt });
    });
    sqmYes.data.forEach((item) => {
      mpsasYesCoords.push({
        x: parseInt(item.readingDate, 10) + 86400,
        y: parseInt(item.mpsas, 10),
      });
      lunarAltYesCoords.push({
        x: parseInt(item.readingDate, 10) + 86400,
        y: item.lunarAlt,
      });
    });

    setMpsas(mpsasCoords);
    setMpsasYes(mpsasYesCoords);
    setLunarAlt(lunarAltCoords);
    setLunarAltYes(lunarAltYesCoords);

    setRecentValue({
      value: mpsasCoords[mpsasCoords.length - 1]?.y,
      time: momenttz(new Date(mpsasCoords[mpsasCoords.length - 1]?.x * 1000))
        .tz(`${settings.station_timezone}`)
        .format("HH:mm"),
    });

    setRecentValueLunar(lunarAltCoords[lunarAltCoords.length - 1]?.y);
  }, [sqm.data, sqmYes.data]);

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        {
          name: "Yesterday",
          data: mpsasYes,
        },
        {
          name: "mag/arcsec<sup>2</sup>",
          data: mpsas,
        },
        {
          name: "lunar",
          data: lunarAlt,
        },
      ],
    });
    setMoonPhase(
      sqm.data === undefined
        ? ""
        : sqm.data[sqm.data.length - 1] === undefined
          ? ""
          : parseFloat(sqm.data[sqm.data.length - 1].lunarPhase) * 100
    );
  }, [sqm]);

  useEffect(() => {
    setSqmHeader(
      <div>
        {
          showSqmHeader &&
          <span className="title__recent-value">
            Moon{" "}
            <span className="chart-card__header-value">
              {RecentValueLunar !== "" ? RecentValueLunar : "_"}&#176;
            </span>
            /
            <span className="chart-card__header-value">
              {MoonPhase !== "" ? parseInt(MoonPhase, 10) : "_"}%
            </span>
            , mag/arcsec<sup>2</sup>{" "}
            <span className="chart-card__header-value">
              {RecentValue.value !== ""
                ? parseFloat(RecentValue.value).toFixed(1)
                : "_"}
            </span>{" "}
            {RecentValueLunar !== "" ? (
              indicator
            ) : (
              <Clock className="clock__icon" />
            )}{" "}
          </span>
        }
      </div>
    )
  }, [RecentValueLunar, MoonPhase]);
  
  return (
    <Chart
      id="sqm-chart"
      options={apex.options}
      series={apex.series}
      type="line"
      height="100%"
    />
  );
}

SqmChart.propTypes = {
  sqm: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  sqmYes: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
  }).isRequired,
};

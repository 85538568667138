/* eslint-disable react/no-unused-prop-types */
import React, { ReactElement } from "react";
import { Row, Col, Card } from "react-bootstrap";

import UpcomingMiniDataTable from "../shared/UpcomingMiniDataTable";
import WidgetCardSmall from "../shared/WidgetCardSmall";
import AllSkyViewWidget from "../shared/AllSkyViewWidget";
import ScienceViewWidget from "../shared/ScienceViewWidget";
import WeatherWidget from "../shared/WeatherWidget";
import { SettingsContext } from "../../hooks/settings-context";

type AppProps = {
  settings: {
    apiEndpoint: string;
    voyager_feed: string;
    station_lat: string;
    station_long: string;
    station_timezone: string;
    weather_station: string;
    allsky_last_image: string;
    science_last_image: string;
    science_last_graph: string;
    scopes: any;
  };
};
export default function ScienceLayout({ settings }: AppProps): ReactElement {
  const radios = [
    { name: "Last image", value: "1" },
    { name: "Last hour", value: "2" },
    { name: "Last night", value: "3" },
  ];
  const ScienceRadios = [
    { name: "Last Science Image", value: "1" },
    { name: "Science Graph", value: "2" },
    { name: "Schedule", value: "3" },
  ];
  return (
    <SettingsContext.Consumer>
      {({ apiEndpoint, data }) => (
        <>
          <Col sm={6} className="full-height">
            <Row className="gy-2 full-height">
              <Col md={6} className="half-height">
                <Row className="full-height">
                  <Col md={12} className="thirty">
                    <UpcomingMiniDataTable apiEndpoint={apiEndpoint} />
                  </Col>
                  <Col className="mt-1" style={{ height: "69%" }} md={12}>
                    <WidgetCardSmall
                      settings={{
                        cctv_stream_1: data.cctv_stream_1,
                        cctv_stream_2: data.cctv_stream_2,
                      }}
                      data={{
                        widget: "CCTV",
                        src: "",
                        style: {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="half-height">
                <AllSkyViewWidget
                  apiEndpoint={apiEndpoint}
                  settings={{
                    station_timezone: data.station_timezone,
                    allsky_last_image: data.allsky_last_image,
                    science_last_image: data.science_last_image,
                    science_last_graph: data.science_last_graph,
                    allsky_asset_directory: data.allsky_asset_directory,
                    allSky_last_video: data.allSky_last_video,
                  }}
                  radios={radios}
                />
              </Col>
              <Col md={6} className="mt-1 half-height">
                <Card className="pb-2 pt-2 px-2 full-height custom-card">
                  <>
                    <h6 className="custom-card__title">
                      Weather/ StormWatch
                      <a href="/weather" className="custom-btn__circular plus">
                        {" "}
                      </a>
                    </h6>
                  </>
                  <WeatherWidget
                    details={{
                      radios: [
                        { name: "Windy", value: "0" },
                        { name: "Satellite", value: "1" },
                        { name: "Jetstream", value: "2" },
                        { name: "StormWatch", value: "3" },
                      ],
                      src: [
                        {
                          url: `https://embed.windy.com/embed2.html?lat=${settings.station_lat}&lon=${settings.station_long}&detailLat=${settings.station_lat}&detailLon=${settings.station_long}&width=800&height=900&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1`,
                          type: "iframe",
                        },
                        {
                          url: `https://www.meteoblue.com/en/weather/maps/widget/${settings.weather_station}?windAnimation=0&windAnimation=0&gust=0&gust=0&satellite=1&satellite=1&coronaWeatherScore=0&coronaWeatherScore=1&geoloc=fixed&tempunit=C&windunit=km%252Fh&lengthunit=metric&zoom=5&autowidth=manu`,
                          type: "iframe",
                        },
                        {
                          url: `https://embed.windy.com/embed2.html?lat=${settings.station_lat}&lon=${settings.station_long}&detailLat=${settings.station_lat}&detailLon=${settings.station_long}&width=650&height=450&zoom=3&level=250h&overlay=wind&product=ecmwf&menu=&message=true&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1`,
                          type: "iframe",
                        },
                        {
                          url: `https://map.blitzortung.org/index.php?interactive=0&NavigationControl=0&FullScreenControl=0&Cookies=0&InfoDiv=1&MenuButtonDiv=1&ScaleControl=1&LinksCheckboxChecked=1&LinksRangeValue=10&MapStyle=3&MapStyleRangeValue=4&Advertisment=0#5/${settings.station_lat}/${settings.station_long}`,
                          type: "iframe",
                        },
                      ],
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Card>
              </Col>
              <Col className="mt-1 half-height" md={6}>
                <ScienceViewWidget
                  radios={ScienceRadios}
                  apiEndpoint={apiEndpoint}
                  settings={settings}
                />
              </Col>
            </Row>
          </Col>
        </>
      )}
    </SettingsContext.Consumer>
  );
}

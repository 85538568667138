/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/ban-types */
import React, { ReactElement, useEffect, useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import moment from "moment";
import SqmChart from "./SqmChart";
import NsmChart from "./NsmChart";
import TempChart from "./TempChart";
import WindChart from "./WindChart";
import CloudChart from "./CloudChart";
import RainChart from "./RainChart";
import AlldylosChart from "./AlldylosChart";
import dataFetch from "../../../actions/fetchDataActions";
import usePersistedState from "../../../hooks/usePersistedState";
// import { nsmWeekly } from "../options";
import ControlPanel from "./ControlPanel";
import { SettingsContext } from "../../../hooks/settings-context"

type AppProps = {
  apiEndpoint: string;
  OPTIONS_WEEKLY: { xaxis: {} };
  urls: {
    recent_url: string;
  };
  setShow: React.Dispatch<React.SetStateAction<{
    status: boolean; data: {}; error: null; message: string;
  }>>
};

export default function ChartWapper({ apiEndpoint, OPTIONS_WEEKLY, urls, setShow }: AppProps): ReactElement {
  const defaultState = { error: null, status: true, items: [] };

  const [weeklySqmData, setweeklySqmData] = usePersistedState(
    "weeklySqmData",
    defaultState
  );
  const [weeklynsmData, setweeklynsmData] = usePersistedState(
    "weeklynsmData",
    defaultState
  );
  const [weeklyalldylosData, setweeklyalldylosData] = usePersistedState(
    "weeklyalldylosData",
    defaultState
  );
  const [weeklysoloData, setweeklysoloData] = usePersistedState(
    "weeklysoloData",
    defaultState
  );

  const [weatherSafety, setWeatherSafety] = usePersistedState(
    "weatherSafety",
    defaultState,
  )

  const [delay, setDelay] = useState(false);
  const [url, setUrl] = useState(urls.recent_url);
  const [options, setOptions] = useState(OPTIONS_WEEKLY)

  const getRange = (dates: string[]) => {
    const data = `?from=${moment(dates[0]).unix()}&to=${moment(
      dates[1]
    ).unix()}`;
    setUrl(data);
    setOptions({
      ...options,
      xaxis: {
        ...options.xaxis,
        min: moment(dates[0]).unix(),
        max: moment(dates[1]).unix()
      }
    })
    setDelay(false)
  };

  useEffect(() => {
    const baseUrl = `${apiEndpoint}/data`;

    if (delay === true) {
      const interval = setInterval(() => {
        // weeklySqmData
        dataFetch(`${baseUrl}/sqm${url}`).then((data) => {
          setweeklySqmData(data);
        });

        // weeklynsmData
        dataFetch(`${baseUrl}/nsm${url}`).then((data) => {
          setweeklynsmData(data);
        });

        // weeklyalldylosData
        dataFetch(`${baseUrl}/dylos${url}`).then((data) => {
          setweeklyalldylosData(data);
        });

        // weeklysoloData
        dataFetch(`${baseUrl}/solo${url}`).then((data) => {
          setweeklysoloData(data);
        });

        dataFetch(`${baseUrl}/weather-safety/${localStorage.getItem('activeScope')}${urls.recent_url}`).then((data) => {
          setWeatherSafety(data);
        });
      }, 120000);
      return () => {
        clearInterval(interval);
      };
    }
    // weeklySqmData
    dataFetch(`${baseUrl}/sqm${url}`).then((data) => {
      setweeklySqmData(data);
    });

    // weeklynsmData
    dataFetch(`${baseUrl}/nsm${url}`).then((data) => {
      setweeklynsmData(data);
    });

    // weeklyalldylosData
    dataFetch(`${baseUrl}/dylos${url}`).then((data) => {
      setweeklyalldylosData(data);
    });

    // // weeklysoloData
    dataFetch(`${baseUrl}/solo${url}`).then((data) => {
      setweeklysoloData(data);
    });

    dataFetch(`${baseUrl}/weather-safety/${localStorage.getItem('activeScope')}${urls.recent_url}`).then((data) => {
      setWeatherSafety(data);
    });
  }, [delay, url, options]);

  return (
    <SettingsContext.Consumer>
      {({ data }) => (
        data && <>
          {weeklySqmData.status === true ? (
            <Spinner
              animation="grow"
              variant="info"
              style={{
                height: "200px",
                width: "200px",
              }}
            />
          ) : (
            <Row className="weekly chart-wrapper full-height">
              <Col sm={12} md={12} lg={12} xl={6} className="twenty-five mb-1">
                {weeklysoloData.status === true ? (
                  "Loading ..."
                ) : (
                  <CloudChart
                    settings={{
                      station_timezone: data.station_timezone,
                    }}
                    weatherSafety={weatherSafety}
                    options={options}
                    cloud={weeklysoloData.items}
                    setDelay={setDelay}
                  />
                )}
              </Col>
              <Col sm={12} md={12} lg={12} xl={6} className="twenty-five mb-1">
                {weeklysoloData.status === true ? (
                  ""
                ) : (
                  <RainChart
                    settings={{
                      station_timezone: data.station_timezone,
                    }}
                    options={options}
                    rain={weeklysoloData.items}
                  />
                )}
              </Col>
              <Col sm={12} md={12} lg={12} xl={6} className="twenty-five mb-1">
                {weeklysoloData.status === true ? (
                  ""
                ) : (
                  <WindChart
                    settings={{
                      station_timezone: data.station_timezone,
                    }}
                    options={options}
                    wind={weeklysoloData.items}
                  />
                )}
              </Col>
              <Col sm={12} md={12} lg={12} xl={6} className="twenty-five mb-1">
                {weeklysoloData.status === true ? (
                  ""
                ) : (
                  <TempChart
                    settings={{
                      station_timezone: data.station_timezone,
                    }}
                    options={options}
                    temps={weeklysoloData.items}

                  />
                )}
              </Col>
              <Col sm={12} md={12} lg={12} xl={6} className="twenty-five mb-1">
                {weeklynsmData.status === true ? (
                  ""
                ) : (
                  <NsmChart
                    settings={{
                      station_timezone: data.station_timezone,
                    }}
                    options={options}
                    nsm={weeklynsmData.items}
                  />
                )}
              </Col>
              <Col sm={12} md={12} lg={12} xl={6} className="twenty-five mb-1">
                {weeklyalldylosData.status === true ? (
                  ""
                ) : (
                  <AlldylosChart
                    settings={{
                      station_timezone: data.station_timezone,
                    }}
                    options={options}
                    alldylos={weeklyalldylosData.items}
                  />
                )}
              </Col>
              <Col sm={12} md={12} lg={12} xl={6} className="twenty-five mb-1">
                {weeklySqmData.status === true ? (
                  ""
                ) : (
                  <SqmChart
                    settings={{
                      station_timezone: data.station_timezone,
                    }}
                    options={options}
                    sqm={weeklySqmData.items}
                  />
                )}
              </Col>
              <Col sm={12} md={12} lg={12} xl={6} className="twenty-five mb-1">
                <ControlPanel apiEndpoint={apiEndpoint} getRange={getRange} setShow={setShow} />
              </Col>
            </Row>
          )}
        </>
      )
      }
    </SettingsContext.Consumer>
  );
}

/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Tooltip,
  OverlayTrigger,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { ZoomIn } from "react-bootstrap-icons";
import Lightbox from "react-awesome-lightbox";
import dataFetch from "../../actions/fetchDataActions";

const AllSkyViewWidget = (props) => {

  const [radioValue, setRadioValue] = useState("1");

  const [lastNight, setLastNight] = useState({
    readingDate: new Date(),
    filename: "",
  });

  const [lastHour, setLastHour] = useState({
    readingDate: new Date(),
    filename: "",
  });

  const [readingTime, setReadingTime] = useState(null);

  const [lastScienceImageUrl, setLastScienceImageUrl] = useState(`${props.apiEndpoint}/data/last-science-image/${localStorage.getItem('activeScope')}`);

  useEffect(() => {
    setInterval(() => {
      setLastScienceImageUrl(lastScienceImageUrl)
    }, 60000);
  }, []);

  const [delay, setDelay] = useState(false);

  const [show, setShow] = useState(false);

  const videoDataFetch = () => {
    dataFetch(`${props.apiEndpoint}/data/skywatch/last-night`)
      .then((data) => {
        if (data.error === null) {
          setLastNight({
            ...data.items.data,
          });
        } else {
          setLastNight({

          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
    dataFetch(`${props.apiEndpoint}/data/skywatch/last-hour`)
      .then((data) => {
        console.log("Last hour", data);
        if (data.error === null) {
          setLastHour(data.items.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    setDelay(true);
  };


  function getDiv(image, title) {
    return (
      <div style={{ display: `${show === true ? "" : "none"}` }}>
        <Lightbox
          image={image}
          title={title || "Science"}
          onClose={() => {
            setShow(false);
          }}
        />
      </div>
    );
  }

  useEffect(() => {
    if (delay === true) {
      const interval = setInterval(() => {
        videoDataFetch();
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
    videoDataFetch();
    setReadingTime(
      lastHour?.readingDate ? parseInt(lastHour.readingDate, 10) : null
    );
  }, []);
  useEffect(() => {
    if (radioValue === "1") {
      setDelay(false);
      setReadingTime(lastHour?.readingDate);
    }
    if (radioValue === "2") {
      setDelay(false);
      setReadingTime(
        lastHour?.readingDate ? parseInt(lastHour.readingDate, 10) : null
      );
    }
    if (radioValue === "3") {
      setDelay(false);
      setReadingTime(
        lastHour?.readingDate ? parseInt(lastNight.readingDate, 10) : null
      );
    }
    setDelay(true);
  }, [radioValue, lastNight, lastHour]);
  let allSkyView;
  if (radioValue === "1") {
    allSkyView = (
      <div className="imageFrame">
        <Card.Img
          src={props.settings.allsky_last_image || "https://obsmoana.com/assets/venus/data-not-available.png"}
          style={{ height: "100%" }}
        />
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            color: "grey",
            cursor: "pointer",
          }}
        >
          <ZoomIn
            onClick={() => {
              setShow(true);
            }}
          />
        </span>
        {getDiv(props.settings.allsky_last_image, "AllSky")}
      </div>
    );
  }
  if (radioValue === "2") {
    allSkyView = (
      lastHour?.filename ? <iframe
        className="videoFrame"
        src={`${props.settings.allSky_last_video}/${lastHour.filename}`}
        allowFullScreen
      /> :
        <Card.Img
          src="https://obsmoana.com/assets/venus/data-not-available.png"
          style={{ height: "85%" }}
        />
    );
  }

  if (radioValue === "3") {
    allSkyView = (
      lastNight?.filename ? <iframe
        className="videoFrame"
        src={`${props.settings.allSky_last_video}/${lastNight.filename}`}
        allowFullScreen
      /> :
        <Card.Img
          src="https://obsmoana.com/assets/venus/data-not-available.png"
          style={{ height: "85%" }}
        />
    );
  }
  if (radioValue === "4") {
    allSkyView = (
      <div className="imageFrame" style={{ position: "relative" }}>
        <Card.Img
          onClick={() => {
            setShow(true);
          }}
          src={lastScienceImageUrl}
          style={{ height: "100%", cursor: "pointer" }}
        />
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            color: "grey",
            cursor: "pointer",
          }}
        >
          <ZoomIn
            onClick={() => {
              setShow(true);
            }}
          />
        </span>
        {getDiv(lastScienceImageUrl)}
      </div>
    );
  }

  return (
    <Card className="pb-2 pt-2 px-2 full-height custom-card">
      <>
        <h6 className="custom-card__title">
          Allsky
          <div className="title__recent-value">
            Last updated {moment(readingTime * 1000).format("DD/MM/YYYY HH:mm")}
            {parseInt(
              moment
                .duration(
                  moment(new Date(), "HH:mm:ss").diff(
                    moment(readingTime * 1000, "HH:mm:ss")
                  )
                )
                .asMinutes() % 60,
              10
            ) < 24 ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip__allSky-2">
                    Last updated {moment(readingTime * 1000).format("DD HH:mm")}
                  </Tooltip>
                }
              >
                <status-indicator
                  active
                  positive
                  pulse
                  style={{ margin: "0 10px" }}
                />
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip__allSky-2">
                    Last updated {moment(readingTime * 1000).format("HH:mm")}
                  </Tooltip>
                }
              >
                <status-indicator
                  active
                  intermediary
                  pulse
                  style={{ margin: "0 10px" }}
                />
              </OverlayTrigger>
            )}
            {props.match.path === "/allSky" ? (
              <a href="/" className="custom-btn__circular minus">
                {" "}
              </a>
            ) : (
              <a href="/allSky" className="custom-btn__circular plus">
                {" "}
              </a>
            )}
          </div>
        </h6>
      </>
      {allSkyView}
      <ButtonGroup className="ButtonGroup-custom" toggle>
        {props.radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="primary"
            name="radio"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => setRadioValue(e.currentTarget.value)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </Card>
  );
};

AllSkyViewWidget.propTypes = {
  radios: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  settings: PropTypes.shape({}).isRequired,
};

export default withRouter(AllSkyViewWidget);

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";

import moment from "moment";
import momenttz from "moment-timezone";
// import { from, to } from "../intervals";

export default function WindChart({ wind, options, settings }) {
  const [RecentValue, setRecentValue] = useState({ value: 0, time: "" });
  const [RecentGustValue, setRecentGustValue] = useState(0);
  const [Average, setAverage] = useState(0);
  const windData = [];
  const gust = [];
  const windLimit1 = [
    { x: parseInt(options.xaxis.min, 10), y: 5 },
    { x: parseInt(options.xaxis.max, 10), y: 5 },
  ];
  const windLimit2 = [
    { x: parseInt(options.xaxis.min, 10), y: 20 },
    { x: parseInt(options.xaxis.max, 10), y: 20 },
  ];

  if (wind.data !== undefined) {
    wind.data.forEach((item) => {
      windData.push({ x: parseInt(item.readingDate, 10), y: item.wind });
      gust.push({ x: parseInt(item.readingDate, 10), y: item.gust });
    });
  }
  const [apex, setApex] = useState({
    options: {
      ...options,
      chart: {
        ...options.chart,
      },
      legend: {
        show: false,
      },
      markers: {
        size: [0, 0, 0, 0, 0],
      },
      fill: {
        type: "solid",
        pattern: {
          style: "squares",
        },
      },
      stroke: {
        ...options.stroke,
        dashArray: [0, 0, 5, 5],
      },
      colors: ["#F6FF00", "#79D2DE", "#fb8500", "#d00000"],
      xaxis: {
        ...options.xaxis,
        tickAmount: 16,
        labels: {
          ...options.xaxis.labels,
        },
      },
      yaxis: [
        {
          ...options.yaxis[0],
          max: 45,
          min: 0,
        },
      ],
    },
    series: [
      {
        data: [],
      },
    ],
  });

  let indicator;
  const duration = parseInt(
    moment
      .duration(
        moment(new Date(), "HH:mm:ss a").diff(
          moment(RecentValue.time, "HH:mm:ss a")
        )
      )
      .asMinutes() % 60,
    10
  );
  if (duration > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__wind">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  } else {
    if (parseInt(RecentValue.value, 10) >= 0 && RecentValue.value <= 5) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__wind">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            positive
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (
      parseInt(RecentValue.value, 10) >= 5 &&
      parseInt(RecentValue.value, 10) <= 20
    ) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__wind">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator active pulse style={{ margin: "0 10px" }} />
        </OverlayTrigger>
      );
    }
    if (parseInt(RecentValue.value, 10) > 20) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__wind">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            negative
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
  }

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        {
          name: "Gust",
          type: "line",
          data: gust,
        },
        {
          name: "Wind",
          type: "line",
          data: windData,
        },
        {
          name: "wind Limit",
          type: "line",
          data: windLimit1,
        },
        {
          name: "wind Limit",
          type: "line",
          data: windLimit2,
        },
      ],
    });

    setRecentValue(
      windData[0] === undefined
        ? { value: "", time: "" }
        : {
            value: windData[windData.length - 1].y,
            time: momenttz(new Date(windData[windData.length - 1].x * 1000))
              .tz(`${settings.station_timezone}`)
              .format("HH:mm"),
          }
    );
    setRecentGustValue(gust[0] === undefined ? "" : gust[gust.length - 1].y);
    setAverage(
      windData[0] === undefined
        ? ""
        : windData.reduce((a, b) => a + parseInt(b.y, 10), 0) / windData.length
    );
  }, [wind]);
  return (
    <Card className="pt-2 px-1 full-height">
      <div>
        <h6 className="chart-card__header">
          Wind
          <span className="title__recent-value">
            average:{" "}
            <span className="chart-card__header-value">
              {Average !== "" ? parseFloat(Average).toFixed(1) : " _ "}
            </span>
            km/h gust:{" "}
            <span className="chart-card__header-value">
              {RecentGustValue !== ""
                ? parseFloat(RecentGustValue).toFixed(1)
                : " _ "}
            </span>
            km/h
            {RecentGustValue !== "" ? (
              indicator
            ) : (
              <Clock className="clock__icon" />
            )}
          </span>
        </h6>
      </div>
      <div className="chart-contianer">
        <Chart
          id="wind-chart"
          options={apex.options}
          series={apex.series}
          type="line"
          height="100%"
        />
      </div>
    </Card>
  );
}

WindChart.propTypes = {
  wind: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
    xaxis: PropTypes.shape({ labels: PropTypes.shape({}) }),
  }).isRequired,
};

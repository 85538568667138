/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, Form, Button, Toast } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "status-indicator/styles.css";
import moment from "moment";
import setScheduleDataActions from "../../../actions/setScheduleDataActions";
import { midNight, midNightTomorrow } from "../intervals";

export default function ControlPanel({getRange, setShow, apiEndpoint}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isDisable, setIsDisable] = useState(true);
  const [status, setStatus] = useState(null);
  const [validated, setValidated] = useState(false);

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <Form.Control
      type="datetime-local"
      size="sm"
      value={moment(value).format("YYYY-MM-DDTHH:mm")}
      onClick={onClick}
      ref={ref}
    />
  ));

  const handleCalendarClose = () => {
    setIsDisable(false);
  };

  const handleGetRange = () => {
    getRange([startDate, endDate]);
  };
  const scheduleHandler = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries(formData.entries());
    const data = {
      frequency: formDataObj.frequency,
      deliveryTime: formDataObj.delivery,
      days: Math.floor(formDataObj.forHours) / 24,
      startTime: formDataObj.startTime,
      endTime: formDataObj.EndTime,
      recipients: `["${formDataObj.email}"]`,
    };
    setScheduleDataActions(`${apiEndpoint}/reports`, data).then(
      (ScheduleData) => {
        setStatus(ScheduleData);
        setShow({ status: true, ...ScheduleData });
      }
    );
  };

  const handlePreset = (e) => {
    const { value } = e.target;
    if (value === "today") {
      setStartDate(midNight);
      setEndDate(midNightTomorrow);
      getRange([midNight, midNightTomorrow]);
    }
    if (value === "yesterday") {
      const yesterdayMidnight = new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).setHours(0, 0, 0, 0);
      setStartDate(yesterdayMidnight);
      setEndDate(midNight);
      getRange([yesterdayMidnight, midNight]);
    }
    if (value === "last7days") {
      const startOfLastWeek = moment()
        .subtract(1, "weeks")
        .startOf("isoWeek")
        .unix();

      const endOfLastWeek = moment()
        .subtract(1, "weeks")
        .endOf("isoWeek")
        .unix();

      setStartDate(startOfLastWeek * 1000);
      setEndDate(endOfLastWeek * 1000);
      getRange([startOfLastWeek * 1000, endOfLastWeek * 1000]);
    }
    if (value === "thismonth") {
      const startOfMonth = moment().startOf("month").unix();
      const endOfMonth = moment().endOf("month").unix();
      setStartDate(startOfMonth * 1000);
      setEndDate(endOfMonth * 1000);
      getRange([startOfMonth * 1000, endOfMonth * 1000]);
    }
    if (value === "last30days") {
      const last30 = moment().subtract(30, "days").unix();
      setStartDate(last30 * 1000);
      setEndDate(moment().unix() * 1000);
      getRange([last30 * 1000, moment().unix() * 1000]);
    }
  };

  useEffect(() => {}, [startDate, endDate, status]);
  return (
    <div style={{ color: "white", height: "100%" }}>
      <Row className="full-height">
        <Col className="full-height">
          <Card className="full-height">
            <Form className="full-height">
              <div className="full-height" style={{ display: "flex" }}>
                <div className="full-height" style={{ paddingRight: "10px" }}>
                  <Form.Group
                    id="formGridCheckbox"
                    onChange={(e) => handlePreset(e)}
                    style={{
                      display: "flex",
                      padding: "0 0 0 10px",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      height: "100%",
                    }}
                  >
                    <Form.Label
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Form.Check name="duration" type="radio" value="today" />
                      Today
                    </Form.Label>
                    <Form.Label
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Form.Check
                        name="duration"
                        type="radio"
                        value="yesterday"
                      />
                      Yesterday
                    </Form.Label>
                    <Form.Label
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Form.Check
                        name="duration"
                        type="radio"
                        value="last7days"
                      />
                      Last 7 Days
                    </Form.Label>

                    {/* <Form.Check
                      name="duration"
                      type="radio"
                      value="thismonth"
                      label="This Month"
                    />
                    <Form.Check
                      name="duration"
                      type="radio"
                      value="last30days"
                      label="Last 30 days"
                    /> */}
                  </Form.Group>
                </div>
                <div
                  style={{
                    padding: "0 0 0 0",
                    margin: "auto",
                    height: "100%",
                    width: "70%",
                  }}
                >
                  <h6>Filter Report Display</h6>
                  <Form.Group controlId="filter.from">
                    <Form.Label>From</Form.Label>
                    <div className="customDatePicker-wrapper">
                      <DatePicker
                        selected={startDate}
                        name="startDate"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="yyyy-MM-dd HH:mm"
                        className="customDatePicker"
                        onChange={(date) => setStartDate(date)}
                        customInput={<CustomDateInput />}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group controlId="filter.from">
                    <Form.Label>To</Form.Label>
                    <div
                      className="customDatePicker-wrapper"
                      style={{ display: "block" }}
                    >
                      <DatePicker
                        name="endDate"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        showTimeSelect
                        dateFormat="yyyy-MM-dd HH:mm"
                        onCalendarClose={handleCalendarClose}
                        customInput={<CustomDateInput />}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      disabled={isDisable}
                      size="sm"
                      variant="primary"
                      type="button"
                      onClick={() => handleGetRange()}
                    >
                      Fetch Report
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Card>
        </Col>
        <Col className="full-height">
          <Card className="pb-3 px-2 pt-1 full-height">
            <h6 style={{ color: "#949496" }}>Schedule Report</h6>
            <Form
              noValidate
              validated={validated}
              onSubmit={scheduleHandler}
              className="full-height"
            >
              <Form.Row>
                <Form.Group as={Col} sm="9">
                  <Form.Label>Send To: </Form.Label>
                  <Form.Control
                    required
                    name="email"
                    type="email"
                    size="sm"
                    placeholder="Enter email"
                  />
                </Form.Group>
                <Form.Group as={Col} sm="3">
                  <Form.Label>For</Form.Label>
                  <Form.Control name="forHours" size="sm" as="select">
                    <option>Select</option>
                    <option value={24}>24</option>
                    <option value={48}>48</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm="3">
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control name="startTime" type="time" size="sm" />
                </Form.Group>
                <Form.Group as={Col} sm="3">
                  <Form.Label>End Time</Form.Label>
                  <Form.Control name="EndTime" type="time" size="sm" />
                </Form.Group>
                <Form.Group as={Col} sm="3">
                  <Form.Label>Delivery time</Form.Label>
                  <Form.Control name="delivery" type="time" size="sm" />
                </Form.Group>
                <Form.Group as={Col} sm="3">
                  <Form.Label>Frequency</Form.Label>
                  <Form.Control name="frequency" size="sm" as="select">
                    <option value="Daily">Daily</option>
                    <option value="Once">Once</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Group style={{ display: "flex", justifyContent: "center" }}>
                <Button variant="primary" type="submit" size="sm">
                  Schedule Report
                </Button>
              </Form.Group>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

ControlPanel.propTypes = {
  getRange: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
};

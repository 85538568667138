import React, { FC, ReactElement } from "react";
import { Container, Row, Col } from "react-bootstrap";
import WidgetCardLarge from "./shared/WidgetCardLarge";
import ChartWapper from "./shared/charts/ChartWapper";
import Footer from "./Footer";
import { SettingsContext } from "../hooks/settings-context";

const CctvPage: FC = (): ReactElement => {
  return (
    <SettingsContext.Consumer>
      {({ apiEndpoint, data }) => (
        <div>
          <Container fluid>
            <Row className="custom__row">
              <Col className="full-height" sm={6}>
                {data.station_timezone && (
                  <ChartWapper
                    apiEndpoint={apiEndpoint}
                    settings={{
                      station_timezone: data.station_timezone,
                    }}
                  />
                )}
              </Col>
              <Col sm={6} className="full-height">
                <WidgetCardLarge
                  settings={{
                    cctv_stream_1: data.cctv_stream_1,
                    cctv_stream_2: data.cctv_stream_2,
                  }}
                  data={{
                    widget: "CCTV",
                    src: "",
                    style: {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                />
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      )}
    </SettingsContext.Consumer>
  );
};

export default CctvPage;

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";

import { Clock } from "react-bootstrap-icons";
import moment from "moment";
import momenttz from "moment-timezone";

export default function NsmChart({ nsm, nsmYes, options, settings, setDustHeader, showDustHeader }) {
  const dateToday = moment().tz(settings.station_timezone).format("YYYY-MM-DD"); // date today in a station timezone

  const fromTime = "19:00";

  const toTime = "6:00";

  const dateTomorrow = moment(dateToday).add(1, "days").format("YYYY-MM-DD");

  const [apex, setApex] = useState({
    options: {
      ...options,
      colors: [
        "#A4A1FB55",
        "##56D9FE55",
        "#A4A1FB",
        "#56D9FE",
        "#fb8500",
        "#d00000",
      ],
      legend: {
        show: false,
      },
      stroke: {
        ...options.stroke,
        dashArray: [0, 0, 0, 0, 3, 3],
      },
      xaxis: {
        ...options.xaxis,
        min: moment(`${dateToday} ${fromTime}`).unix(),
        max: moment(`${dateTomorrow} ${toTime}`).unix(),
      },
      yaxis: [
        {
          ...options.yaxis[0],
          min: 0,
          max: 6,
          labels: {
            ...options.yaxis[0].labels,
            formatter(val) {
              return val;
            },
          },
        },
      ],
    },
    series: [],
  });
  const [RecentValue, setRecentValue] = useState({ value: 0, time: "" });
  const CorrectedFWHM = [];
  const UncorrectedFWHM = [];

  const CorrectedFWHMYes = [];
  const UncorrectedFWHMYes = [];
  const correctedLimit1 = [
    { x: apex.options.xaxis.min, y: "1.5" },
    { x: apex.options.xaxis.max, y: "1.5" },
  ];
  const correctedLimit2 = [
    { x: apex.options.xaxis.min, y: "3.5" },
    { x: apex.options.xaxis.max, y: "3.5" },
  ];

  let indicator;
  const duration = parseInt(
    moment
      .duration(
        moment(new Date(), "HH:mm:ss a").diff(
          moment(RecentValue.time, "HH:mm:ss a")
        )
      )
      .asMinutes() % 60,
    10
  );
  if (duration > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__nsm">
            Last updated{" "}
            {momenttz(RecentValue.time)
              .tz(settings.station_timezone)
              .format("DD/MM/YYY")}
          </Tooltip>
        }
      >
        &quot; <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  } else {
    if (
      parseInt(RecentValue.value, 10) >= 0 &&
      parseInt(RecentValue.value, 10) <= 1.5
    ) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__nsm">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            positive
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (
      parseInt(RecentValue.value, 10) >= 1.5 &&
      parseInt(RecentValue.value, 10) <= 3
    ) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__nsm1">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            Intermediary
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (parseInt(RecentValue.value, 10) > 3) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__nsm">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            negative
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
  }

  if (nsm.data !== undefined && nsmYes.data !== undefined) {
    nsm.data.forEach((item) => {
      CorrectedFWHM.push({
        x: parseInt(item.readingDate, 10),
        y: parseFloat(item.corrected).toFixed(1),
      });
      UncorrectedFWHM.push({
        x: parseInt(item.readingDate, 10),
        y: parseFloat(item.uncorrected).toFixed(1),
      });
    });
    nsmYes.data.forEach((item) => {
      CorrectedFWHMYes.push({
        x: parseInt(item.readingDate, 10) + 86400,
        y: item.corrected,
      });
      UncorrectedFWHMYes.push({
        x: parseInt(item.readingDate, 10) + 86400,
        y: item.uncorrected,
      });
    });
  }

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
        },
      },
      series: [
        {
          name: "Corrected FWHMYes",
          data: CorrectedFWHMYes,
        },
        {
          name: "Uncorrected FWHMYes",
          data: UncorrectedFWHMYes,
        },
        {
          name: "Corrected FWHM",
          data: CorrectedFWHM,
        },
        {
          name: "Uncorrected FWHM",
          data: UncorrectedFWHM,
        },
        {
          name: "limit1",
          data: correctedLimit1,
        },
        {
          name: "limit2",
          data: correctedLimit2,
        },
      ],
    });
    setRecentValue(
      CorrectedFWHM[0] === undefined
        ? { value: "", time: "" }
        : {
            value: CorrectedFWHM[0].y,
            time: momenttz(new Date(CorrectedFWHM[0].x))
              .tz(`${settings.station_timezone}`)
              .format("HH:mm:ss a"),
          }
    );
  }, [nsm, nsmYes, options]);

  useEffect(() => {
    setDustHeader(() => (
      <div>
        {
          showDustHeader &&
          <span className="title__recent-value">
            Seeing:{" "}
            <span className="chart-card__header-value">
              {RecentValue.value === "" ? "_" : parseFloat(RecentValue.value)}
              &rdquo;
            </span>
            {RecentValue.value === "" ? (
              <Clock className="clock__icon" />
            ) : (
              // indicator
              ""
            )}
          </span>
        }
      </div>
    ))
  }, [])

  return (
    <Chart
      id="nsm-chart"
      options={apex.options}
      series={apex.series}
      type="line"
      height="100%"
    />
  );
}

NsmChart.propTypes = {
  nsm: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  nsmYes: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
  }).isRequired,
};

import moment from "moment";

const now = new Date();
export const nowunix = moment(now).unix();
export const from48 = moment(now).subtract({ hours: 48 }).unix();
export const before18hr = moment(now).subtract({ hours: 18 }).unix();
export const after6hr = moment(now).add({ hours: 6 }).unix();
export const pastBefore18hr = moment(moment(now).subtract({ hours: 18 }))
  .subtract({ hours: 24 })
  .unix();
const pastAfter6hr = moment(moment(now).add({ hours: 6 }))
  .subtract({ hours: 24 })
  .unix();

export const midNight = now.setHours(0, 0, 0);
export const midNightTomorrow = now.setHours(24, 0, 0, 0);

const date = moment(midNight);
const begin = moment(date).isoWeekday(1);

export const from = begin.unix();
begin.startOf("week");
begin.add("d", 7);
export const to = begin.unix();

export const URLsDaily = {
  recent_url: `?from=${before18hr}&to=${after6hr}`,
  past_url: `?from=${pastBefore18hr}&to=${pastAfter6hr}`,
};

export const URLsWeekly = {
  recent_url: `?from=${from48}&to=${nowunix}`,
};

import React, { ReactElement } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import WidgetCardSmall from "./shared/WidgetCardSmall";
import WeatherWidget from "./shared/WeatherWidget";
import Footer from "./Footer";
import { SettingsContext } from "../hooks/settings-context";

export default function Weather(): ReactElement {
  return (
    <SettingsContext.Consumer>
      {({ data }) => (
        <div>
          <Container fluid>
            <Row className="mx-1 custom__row">
              <Col lg={6} md={12} className="full-height">
                <Row className="full-height">
                  <Col md={6} className="full-height">
                    <Col md={12} style={{ height: "50%" }}>
                      <Card className="pb-1 pt-1 px-1 full-height">
                        <h6>Satellite</h6>
                        <WeatherWidget
                          details={{
                            radios: [
                              { name: "Meteoblue", value: "0" },
                              { name: "Satellite 3Hr", value: "1" },
                              { name: "Radar 3hr", value: "2" },
                              { name: "Rain 3hr", value: "3" },
                            ],
                            src: [
                              {
                                url: `https://www.meteoblue.com/en/weather/maps/widget/${data.weather_station}?windAnimation=0&windAnimation=0&gust=0&gust=0&satellite=1&satellite=1&coronaWeatherScore=0&coronaWeatherScore=1&geoloc=fixed&tempunit=C&windunit=km%252Fh&lengthunit=metric&zoom=5&autowidth=manu`,
                                type: "iframe",
                              },
                              {
                                url: data.statelite_1_widget || "",
                                type: "iframe",
                              },
                              {
                                url: data.statelite_2_widget || "",
                                type: "iframe",
                              },
                              {
                                url: data.statelite_3_widget || "",
                                type: "iframe",
                              },
                            ],
                          }}
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      </Card>
                    </Col>
                    <Col className="mt-2" md={12} style={{ height: "50%" }}>
                      <Card className="pb-1 pt-1 px-1 full-height">
                        <h6>Forecast</h6>
                        <div style={{ height: " 100%" }}>
                          <img
                            style={{
                              height: "94%",
                              width: "100%",
                            }}
                            alt=""
                            src={data.forecast_widget}
                          />
                        </div>
                      </Card>
                    </Col>
                  </Col>
                  <Col md={6} className="full-height">
                    <Col md={12} style={{ height: "30%" }}>
                      <WidgetCardSmall
                        settings={{
                          cctv_stream_1: data.cctv_stream_1,
                          cctv_stream_2: data.cctv_stream_2,
                        }}
                        data={{
                          widget: "Jetstream",
                          src: `https://embed.windy.com/embed2.html?lat=${data.station_lat}&lon=${data.station_long}&detailLat=${data.station_lat}&detailLon=${data.station_long}&width=650&height=450&zoom=3&level=250h&overlay=wind&product=ecmwf&menu=&message=true&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1`,
                          style: {
                            width: "100%",
                            height: "100%",
                          },
                        }}
                      />
                    </Col>
                    <Col className="mt-2" md={12} style={{ height: "70%" }}>
                      <WidgetCardSmall
                        settings={{
                          cctv_stream_1: data.cctv_stream_1,
                          cctv_stream_2: data.cctv_stream_2,
                        }}
                        data={{
                          widget: "Meteoblue Seeing",
                          src: `https://www.meteoblue.com/en/weather/widget/seeing/${data.weather_station}?geoloc=fixed&noground=0`,
                          style: {
                            width: "100%",
                            height: "100%",
                          },
                        }}
                      />
                    </Col>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12} className="full-height">
                <Card className="pb-1 pt-1 px-1 full-height">
                  <Card.Body className="p-0">
                    <Card.Title
                      className="h6"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Weather
                      <a href="/" className="custom-btn__circular minus">
                        {" "}
                      </a>
                    </Card.Title>
                  </Card.Body>
                  <WeatherWidget
                    details={{
                      radios: [
                        { name: "Windy", value: "0" },
                        { name: "Meteo", value: "1" },
                        { name: "ClearOutside", value: "2" },
                        { name: "Seeing Detail", value: "3" },
                      ],
                      src: [
                        {
                          url: `https://embed.windy.com/embed2.html?lat=${data.station_lat}15&lon=${data.station_long}37&detailLat=${data.station_lat}15&detailLon=${data.station_long}37&width=800&height=900&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1`,
                          type: "iframe",
                        },
                        {
                          url: `https://www.meteoblue.com/en/weather/maps/widget/${data.weather_station}?windAnimation=0&windAnimation=1&gust=0&gust=1&satellite=0&satellite=1&coronaWeatherScore=0&coronaWeatherScore=1&geoloc=fixed&tempunit=C&windunit=km%252Fh&lengthunit=metric&zoom=5&autowidth=manu`,
                          type: "iframe",
                        },
                        {
                          url: `https://clearoutside.com/forecast/${data.station_lat}/${data.station_long}`,
                          type: "iframe",
                        },
                        {
                          url: `https://www.meteoblue.com/en/weather/outdoorsports/seeing/${data.weather_station}`,
                          type: "iframe",
                        },
                      ],
                    }}
                    style={{
                      height: "100%",
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      )}
    </SettingsContext.Consumer>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";
import moment from "moment";
import momenttz from "moment-timezone";

export default function AlldylosChart({ alldylos, options, settings }) {
  const small = [];

  const large = [];
  if (alldylos.data !== undefined) {
    alldylos.data.forEach((item) => {
      small.push({ x: parseInt(item.readingDate, 10), y: item.small });
      large.push({ x: parseInt(item.readingDate, 10), y: item.large });
    });
  }
  const [apex, setApex] = useState({
    options: {
      ...options,
      colors: ["#F6AE2D", "#FF8373"],
      legend: {
        show: true,
        labels: {
          colors: "#fff",
        },
      },
    },
    series: [
      {
        name: "Small",
        data: [],
      },
      {
        name: "Large",
        data: [],
      },
    ],
  });

  const [RecentValue, setRecentValue] = useState({ value: 0, time: "" });
  const [RecentValueLarge, setRecentValueLarge] = useState(0);
  let indicator;
  const duration = parseInt(
    moment
      .duration(
        moment(new Date(), "HH:mm:ss a").diff(
          moment(RecentValue.time, "HH:mm:ss a")
        )
      )
      .asMinutes() % 60,
    10
  );
  if (duration > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__dylos">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  } else if (parseInt(RecentValue, 10) > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__dylo">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <status-indicator active pulse style={{ margin: "0 10px" }} />
      </OverlayTrigger>
    );
  } else {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__dylos">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <status-indicator active pulse positive style={{ margin: "0 10px" }} />
      </OverlayTrigger>
    );
  }

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        {
          name: ">2.5um",
          data: small,
        },
        {
          name: ">10um",
          data: large,
        },
      ],
    });

    setRecentValue(
      small[0] === undefined
        ? { value: "", time: 0 }
        : {
            value: small[small.length - 1].y,
            time: momenttz(new Date(small[small.length - 1].x * 1000))
              .tz(`${settings.station_timezone}`)
              .format("HH:mm"),
          }
    );
    setRecentValueLarge(
      large[0] === undefined ? "" : large[large.length - 1].y
    );
  }, [alldylos]);
  return (
    <Card className="pt-2 px-1 full-height">
      <div>
        <h6 className="chart-card__header ">
          Dust Particle Monitor
          <span className="title__recent-value">
            {" "}
            &gt;2.5&mu;m{" "}
            <span className="chart-card__header-value">
              {RecentValue.value !== ""
                ? parseFloat(RecentValue.value).toFixed(1)
                : "_"}
            </span>{" "}
            &gt;10&mu;m{" "}
            <span className="chart-card__header-value">
              {RecentValueLarge !== ""
                ? parseFloat(RecentValueLarge).toFixed(1)
                : "_"}
            </span>{" "}
            {RecentValue.value === "" ? (
              <Clock className="clock__icon" />
            ) : (
              indicator
            )}
          </span>
        </h6>
      </div>
      <div className="chart-contianer">
        <Chart
          id="dylos-chart"
          options={apex.options}
          series={apex.series}
          type="line"
          height="100%"
        />
      </div>
      <div style={{ color: "white", fontSize: ".6rem" }}>
        Particulate density &mu;g/m<sup>3</sup>
      </div>
    </Card>
  );
}

AlldylosChart.propTypes = {
  alldylos: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
  }).isRequired,
};

/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import StormStation5NGX from "./StormStation5NGX";
import { SettingsContext } from "../../hooks/settings-context";

type AppProps = {
  settings: {
    station_lat: string;
    station_long: string;
  };
};

export default function StormStationWidget({ settings }: AppProps) {
  const [radioValue, setRadioValue] = useState("2");
  const radios = [
    { name: "StormStation5NGX", value: "1" },
    { name: "Blitzortung", value: "2" },
  ];

  let StormWatch;
  if (radioValue === "1") {
    StormWatch = <StormStation5NGX height="calc(100vh/1.74 - 320px)" />;
  }
  if (radioValue === "2") {
    StormWatch = (
      <iframe
        title="blitzortung"
        src={`https://map.blitzortung.org/index.php?interactive=0&NavigationControl=0&FullScreenControl=0&Cookies=0&InfoDiv=1&MenuButtonDiv=1&ScaleControl=1&LinksCheckboxChecked=1&LinksRangeValue=10&MapStyle=3&MapStyleRangeValue=4&Advertisment=0#5/${settings.station_lat}/${settings.station_long}`}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    );
  }
  return (
    <>
      {StormWatch}
      <ButtonGroup className="ButtonGroup-custom pt-3" toggle>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="primary"
            name="radio"
            size="sm"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => setRadioValue(e.currentTarget.value)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  );
}

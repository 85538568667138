import React from "react";

export const settings = {
  default: {
    apiEndpoint: "",
    id: "",
    name: "",
    data: {
      app_layout: "",
      app_logo: "",
      app_name: "",
      cctv_stream_1: "",
      cctv_stream_2: "",
      forecast_widget: "",
      lunar_phase: "",
      multi_scopes: "false",
      science_last_image: "",
      scopes: "{}",
      science_last_graph: "",
      statelite_1_widget: "",
      statelite_2_widget: "",
      statelite_3_widget: "",
      station_lat: "",
      station_long: "",
      station_timezone: "",
      weather_station: "",
      allsky_asset_directory: "",
      allsky_last_image: "",
      allSky_last_video: "",
      voyager_feeds: [],
      voyager_feed: "",
    },
  },
};

export const SettingsContext = React.createContext(
  settings.default // default value
);

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const setScheduleDataActions = (url: string, data: any) =>
  fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        if (result.error) {
          return {
            ...result,
            message: "Something went wrong",
          };
        }
        return {
          ...result,
          error: null,
          message: "The Schedule was sent successfully",
        };
      },
      (error) => {
        return {
          ...error,
          message: "Something went wrong",
        };
      }
    );

export default setScheduleDataActions;


/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import "./scss/App.scss";
import React, { FC, ReactElement, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Navigation from "./Components/shared/Navigation";
import Weather from "./Components/Weather";
import Lightning from "./Components/Lightning";
import CctvPage from "./Components/CctvPage";
import Monitor from "./Components/Monitor";
import AllSky from "./Components/AllSky";
import Telemetrics from "./Components/Telemetrics";
import Iss from "./Components/Iss";
import Solar from "./Components/Solar";
import Lunar from "./Components/Lunar";
import ChartsTemplate from "./Components/ChartsTemplate";
import { SettingsContext, settings } from "./hooks/settings-context";
import dataFetch from "./actions/fetchDataActions";
import usePersistedSettings from "./hooks/usePersistedSettings";

const App: FC = (): ReactElement => {
  const [appSettings, setAppSettings] = usePersistedSettings(
    "settings",
    settings
  );

  useEffect(() => {
    const domain = window.location.href.split("/");
    let apiEndpoint = "";
    switch (window.location.hostname) {
      case "localhost":
        apiEndpoint =
          "https://utah.obsmoana.com:9090";
        break;
      default:
        apiEndpoint = `https://${domain[2]}:9090`;
        break;
    }
    dataFetch(`${apiEndpoint}/utils/value-objects`)
      .then((data) => {
        const defaultSettings = data.items.data[0].data;
        const voyagerFeeds = JSON.parse(defaultSettings?.voyager_feeds || []);
        let activeScope = localStorage.getItem("activeScope");

        if (JSON.parse(defaultSettings?.multi_scopes || "false")) {
          const scopeSettings = JSON.parse(defaultSettings?.scopes || "{}");
          const scopeNames = Object.keys(scopeSettings);

          if (!activeScope || !scopeNames.includes(activeScope)) {
            [activeScope] = scopeNames;
          }

          localStorage.setItem("activeScope", activeScope);

          const activeScopeSettings = scopeSettings?.[activeScope];

          setAppSettings({
            default: {
              ...data.items.data[0],
              apiEndpoint,
              data: {
                ...data.items.data[0].data,
                ...activeScopeSettings,
                voyager_feed: activeScope,
              }
            },
          });
        } else {
          if (!activeScope || !voyagerFeeds.includes(activeScope)) {
            [activeScope] = voyagerFeeds;
            localStorage.setItem('activeScope', activeScope || "");
          }

          setAppSettings({
            default: {
              ...data.items.data[0],
              apiEndpoint,
              data: {
                ...data.items.data[0].data,
                voyager_feed: activeScope,
              }
            },
          });
        }
      })
      .catch(() => {
        //
      });

  }, [appSettings.default.name]);

  return (
    <div className="App">
      <SettingsContext.Provider value={appSettings.default}>
        <Router>
          <Navigation />
          <Switch>
            <Route exact path="/" component={Monitor} />
            <Route exact path="/Telemetrics" component={Telemetrics} />
            {/* <Route exact path="/Observability" /> */}
            <Route exact path="/lunar" component={Lunar} />
            <Route exact path="/charts" component={ChartsTemplate} />
            <Route exact path="/solar" component={Solar} />
            <Route exact path="/iss" component={Iss} />
            <Route exact path="/allSky" component={AllSky} />
            <Route exact path="/weather" component={Weather} />
            <Route exact path="/lightning" component={Lightning} />
            <Route exact path="/cctv" component={CctvPage} />
          </Switch>
        </Router>
      </SettingsContext.Provider>
    </div>
  );
};

export default App;

/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Card,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Lightbox from "react-awesome-lightbox";
import { ZoomIn } from "react-bootstrap-icons";
import dataFetch from "../../actions/fetchDataActions";

const ScienceViewWidget = ({ settings, voyager_feed, radios, apiEndpoint }) => {
  const [radioValue, setRadioValue] = useState("1");
  const [lastNight, setLastNight] = useState({
    readingDate: new Date(),
    filename: "",
  });
  const [lastHour, setLastHour] = useState({ readingDate: 0, filename: "" });
  const [delay, setDelay] = useState(false);
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [voyagerData, setVoyagerData] = useState({});
  const [lastScienceStatus, setLastScienceStatus] = useState("");

  function getDiv(image) {
    return (
      <div style={{ display: `${show === true ? "" : "none"}` }}>
        <Lightbox
          image={image.scienceViewURL || "https://obsmoana.com/assets/venus/data-not-available.png"}
          title="Science"
          onClose={() => {
            setShow(false);
          }}
        />
      </div>
    );
  }

  const [scienceViewURL, setScienceViewURL] = useState("");
  const [recentStateURL, setRecentStateURL] = useState("");

  useEffect(() => {
    if (settings.voyager_feed === null) {
      return;
    }

    const voyagerDataFetch = (scope) => {
      dataFetch(`${apiEndpoint}/data/voyager/${scope}`)
        .then((data) => {
          if (data.error === null) {
            setVoyagerData(data.items.data);
          }
        })
        .catch((e) => {
          console.log(e);
        })
    }

    voyagerDataFetch(settings.voyager_feed);
    setRecentStateURL(settings.scopes?.[settings.voyager_feed]?.recent_state_url);

    setInterval(() => {
      voyagerDataFetch(settings.voyager_feed);
      setLastScienceStatus(`${voyagerData?.lastScience?.target} UTC ${moment(voyagerData?.lastScience?.timestamp * 1000).utc().format("DD/MM/YYYY HH:mm")}`);
      setRecentStateURL(settings.scopes?.[settings.voyager_feed]?.recent_state_url);
    }, 60000);
  }, [apiEndpoint, voyager_feed, voyagerData?.lastScience?.target, voyagerData?.lastScience?.timestamp]);

  useEffect(() => {
    setLastScienceStatus(`${voyagerData?.lastScience?.target} UTC ${moment(voyagerData?.lastScience?.timestamp * 1000).utc().format("DD/MM/YYYY HH:mm")}`)
  }, [voyagerData?.lastScience?.target, voyagerData?.lastScience?.timestamp]);

  const videoDataFetch = () => {
    dataFetch(`${apiEndpoint}/data/skywatch/last-night`)
      .then((data) => {
        if (data.error === null) {
          setLastNight({
            ...data.items.data,
          });
        }
      })
      .catch(() => {
        //
      });
    dataFetch(`${apiEndpoint}/data/skywatch/last-hour`)
      .then((data) => {
        if (data.error === null) {
          setLastHour(data.items.data);
        }
      })
      .catch(() => {
        //
      });
    setDelay(true);
  };

  useEffect(() => {
    if (delay === true) {
      const interval = setInterval(() => {
        videoDataFetch();
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [lastNight.readingDate, lastHour.readingDate, radioValue]);

  useEffect(() => {
    if (refresh === true) {
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    switch (parseInt(radioValue || "0", 10)) {
      case 1:
        setScienceViewURL(`${apiEndpoint}/data/last-science-image/${settings.voyager_feed}`);
        break;
      case 2:
        setScienceViewURL(settings.science_last_graph);
        break;
      case 3:
        setScienceViewURL(recentStateURL);
        break;
      default:
        setScienceViewURL("");
        break;
    }
  }, [radioValue, settings, apiEndpoint])

  return (
    <>
      <Card className="pb-2 pt-2 px-2 full-height custom-card">
        <>
          <h6 className="custom-card__title">
            Science
            <div className="title__recent-value">
              {lastScienceStatus}
            </div>
          </h6>
        </>
        <div className="imageFrame" style={{ position: "relative" }}>
          <Card.Img
            onClick={() => {
              setShow(true);
            }}
            src={scienceViewURL || "https://obsmoana.com/assets/venus/data-not-available.png"}
            style={{ height: "100%" }}
          />
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              color: "grey",
              cursor: "pointer",
            }}
          >
            <ZoomIn
              onClick={() => {
                setShow(true);
              }}
            />
          </span>
          {getDiv({ scienceViewURL })}
        </div>
        <ButtonGroup className="ButtonGroup-custom" toggle>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              type="radio"
              variant="primary"
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </Card>
    </>
  );
};

ScienceViewWidget.propTypes = {
  radios: PropTypes.arrayOf({}).isRequired,
  settings: PropTypes.shape({}).isRequired,
};

export default withRouter(ScienceViewWidget);

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const dataFetch = (url: string) =>
  fetch(url)
    .then((res) => res.json())
    .then(
      (result) => {
        return {
          error: null,
          status: false,
          items: result,
        };
      },
      (error) => {
        return {
          error,
          status: false,
          items: [],
        };
      }
    );

export default dataFetch;

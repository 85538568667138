import React, { ReactElement, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Col, Row } from "react-bootstrap";
import dataFetch from "../../../actions/fetchDataActions";

type GuidingChartProps = {
    apiEndpoint: string,
    data: any
}

const GuidingChart = ({ apiEndpoint, data }: GuidingChartProps): ReactElement => {

    const [xLimit, setXLimit] = useState<number>(20);

    const [scatterChartOptions, setScatterChartOptions] = useState({
        chart: {
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            }
        },
        tooltip: {
            x: {
                show: false,
            },
        },
        stroke: {
            width: 2,
        },
        markers: {
            size: 1,
        },
        dataLabels: {
            enabled: false,
        },
        noData: {
            text: "No Data Available",
            style: {
                color: "#a3a3a3",
                fontSize: "14px",
            },
        },
        xaxis: {
            min: -2,
            tickAmount: 2,
            labels: {
                show: true,
            }
        },
        yaxis: {
            tickAmount: 2,
            labels: {
                show: true,
            }
        }
    });

    const [seriesChartOptions, setSeriesChartOptions] = useState({
        chart: {
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            }
        },
        legend: {
            show: true,
            labels: {
                colors: "#fff",
            },
            markers: {
                width: 7,
                height: 7,
            },
        },
        tooltip: {
            x: {
                show: false,
            },
        },
        stroke: {
            width: 2,
        },
        markers: {
            size: 0,
        },
        dataLabels: {
            enabled: false,
        },
        noData: {
            text: "No Data Available",
            style: {
                color: "#a3a3a3",
                fontSize: "14px",
            },
        },
        xaxis: {
            min: 0,
            tickAmount: 10,
            labels: {
                show: true,
                style: {
                    colors: "#A3A3A3",
                    padding: "10px",
                },
            }
        },
        yaxis: {
            tickAmount: 2,
            labels: {
                show: true,
                style: {
                    colors: "#A3A3A3",
                    padding: "10px",
                },
                formatter: (val: number) => { return val.toFixed(2) },
            }
        }
    });

    const [guidingPoints, setGuidingPoints] = useState<Array<Array<number>>>([]);
    const [guideXSeries, setGuideXSeries] = useState<Array<Array<number>>>([]);
    const [guideYSeries, setGuideYSeries] = useState<Array<Array<number>>>([]);

    const [guidingScatterChartSeries, setGuidingScatterChartSeries] = useState([
        {
            name: "Guiding",
            data: guidingPoints,
        },
    ])

    const [guidingSeriesChartSeries, setGuidingSeriesChartSeries] = useState([
        {
            name: "Guide X",
            data: guideXSeries,
        },
        {
            name: "Guide Y",
            data: guideYSeries,
        }
    ]);

    useEffect(() => {
        const getGuidingData = () => {
            const scatterCoords: Array<Array<number>> = [];
            const guideXCoords: Array<Array<number>> = [];
            const guideYCoords: Array<Array<number>> = [];

            const activeScope = localStorage.getItem('activeScope');
            dataFetch(`${apiEndpoint}/data/guiding/${activeScope}?limit=${xLimit}`)
                .then((res) => {
                    if (res.items.status === "200") {
                        Object.values(res.items.data).forEach((point: any, index: number) => {
                            if (parseFloat(point.guideX) > 2 || parseFloat(point.guideY) > 2) {
                                return;
                            }

                            scatterCoords.push([parseFloat(point.guideX), parseFloat(point.guideY)]);
                            guideXCoords.push([index, parseFloat(point.guideX)]);
                            guideYCoords.push([index, parseFloat(point.guideY)]);
                        })

                        setGuidingPoints(scatterCoords);
                        setGuideXSeries(guideXCoords);
                        setGuideYSeries(guideYCoords);
                    }
                })
        }

        const refreshInterval = setInterval(() => {
            getGuidingData();

        }, 60000);

        getGuidingData();
    }, [apiEndpoint, data, xLimit]);

    useEffect(() => {
        setGuidingScatterChartSeries([
            {
                name: "Guiding",
                data: guidingPoints,
            }]);

        setGuidingSeriesChartSeries([
            {
                name: "Guide X",
                data: guideXSeries,
            },
            {
                name: "Guide Y",
                data: guideYSeries,
            }
        ]);
    }, [guidingPoints, guideXSeries, guideYSeries]);

    const changeXAxis = (e: any) => {
        setXLimit(e.target.value);
    }

    return (
        <Card className="pt-2 px-1 full-height">
            <div>
                <h6 className="chart-card__header">
                    Guiding (Hybrid Line)
                    <select onChange={changeXAxis} value={xLimit}>
                        <option value="20">x: 20</option>
                        <option value="100">x: 100</option>
                        <option value="200">x: 200</option>
                    </select>
                </h6>
            </div>
            <div className="chart-contianer">
                <ReactApexChart
                    options={seriesChartOptions}
                    series={guidingSeriesChartSeries}
                    type="line"
                    width="100%"
                    height="100%"
                />
            </div>
        </Card>
    )
}

export default GuidingChart;
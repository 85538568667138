/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from "react";
import { ButtonGroup, ToggleButton, Image } from "react-bootstrap";

type AppProp = {
  style: {
    height?: string;
    maxHeight?: string;
    width?: string;
    margin?: string;
  };
  details: {
    src: Array<string>;
    radios: Array<{ value: string; name: string }>;
    type: string;
  };
};

export default function SolarWidget({ style, details }: AppProp) {
  const [radioValue, setRadioValue] = useState("0");
  const { radios } = details;

  let src;
  let index = 0;
  const len = details.src.length;
  while (index < len) {
    if (radioValue === index.toString()) {
      src = details.src[index];
    }
    index += 1;
  }

  return (
    <div className="full-height">
      <div
        style={{
          height: "84%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image src={src} alt="solarimage" style={style} />
      </div>
      {details.type === "image" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "8px",
            height: "10%",
          }}
        >
          {radios.map((radio, idx) => (
            <Image
              key={idx}
              src={radio.name}
              style={{
                margin: "0 5px",
              }}
              thumbnail={idx.toString() === radioValue}
              onClick={() => {
                setRadioValue(idx.toString());
              }}
            />
          ))}
        </div>
      ) : (
        <ButtonGroup className="ButtonGroup-custom pt-1" toggle>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              type="radio"
              variant="primary"
              name="radio"
              size="sm"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      )}
    </div>
  );
}

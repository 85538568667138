/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import momenttz from "moment-timezone";
import { Clock } from "react-bootstrap-icons";

function TempChart({ temps, tempsYes, options, settings }) {
  const [RecentValue, setRecentValue] = useState({ value: 0, time: "", lastReading: "" });
  const [RecentValueDew, setRecentValueDew] = useState(0);
  const temp = [];
  const tempYes = [];
  const dewPoint = [];
  const dewPointYes = [];
  if (temps.data !== undefined && tempsYes.data !== undefined) {
    temps.data.forEach((item) => {
      temp.push({ x: parseInt(item.readingDate, 10), y: item.temp });
      dewPoint.push({ x: parseInt(item.readingDate, 10), y: item.dewPoint });
    });
    tempsYes.data.forEach((item) => {
      tempYes.push({ x: parseInt(item.readingDate, 10) + 86400, y: item.temp });
      dewPointYes.push({
        x: parseInt(item.readingDate, 10) + 86400,
        y: item.dewPoint,
      });
    });
  }
  const [apex, setApex] = useState({
    options: {
      ...options,
      colors: ["#F6AE2D55 ", "#46A8DF55", "#F6AE2D", "#46A8DF"],
      yaxis: [
        {
          ...options.yaxis[0],
          labels: {
            ...options.yaxis[0].labels,
            formatter(val) {
              return val?.toFixed(1);
            },
          },
        },
      ],
    },
    series: [
      {
        data: [],
      },
      {
        data: [],
      },
    ],
  });

  let indicator;
  const isStaleData = (readingDate, stalePeriod, tag = '') => {
    const lastReading = moment.unix(readingDate).tz(settings.station_timezone).format("DD/MM/YY HH:mm:ss a").toString();
    const currentTime = moment(new Date()).tz(settings.station_timezone).format("DD/MM/YY HH:mm:ss a").toString();
    const readingInterval = moment.duration(
      moment(currentTime, "DD/MM/YY HH:mm:ss a")
        .diff(moment(lastReading, "DD/MM/YY HH:mm:ss a"))
    ).asMinutes();

    return (Math.floor(readingInterval) > stalePeriod);
  }

  const clockIndicator = (lastUpdated) => {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__cloud-1">
            Last updated {moment.unix(lastUpdated).tz(settings.station_timezone).format("DD-MMM HH:mm:ssa")}
          </Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  }

  if (isStaleData(RecentValue.lastReading, 15)) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__temp">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        {clockIndicator(RecentValue.lastReading)}
      </OverlayTrigger>
    );
  } else if (
    parseInt(RecentValue.value, 10) - parseInt(RecentValueDew, 10) <
    5
  ) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__temp">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <status-indicator active negative pulse style={{ margin: "0 10px" }} />
      </OverlayTrigger>
    );
  } else {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__temp">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <status-indicator active positive pulse style={{ margin: "0 10px" }} />
      </OverlayTrigger>
    );
  }

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        { name: "Yesterday", data: tempYes },
        { name: "Yesterday", data: dewPointYes },
        { name: "Temperature", data: temp },
        { name: "Dew point", data: dewPoint },
      ],
    });

    setRecentValue(
      temp[0] === undefined
        ? { ...RecentValue }
        : {
          value: temp[temp.length - 1].y,
          time: momenttz(new Date(temp[temp.length - 1].x * 1000))
            .tz(`${settings.station_timezone}`)
            .format("HH:mm"),
          lastReading: temp[temp.length - 1].x,
        }
    );
    setRecentValueDew(
      dewPoint[0] === undefined || dewPoint[0].y === null
        ? ""
        : dewPoint[dewPoint.length - 1].y
    );
  }, [temps, tempsYes, options]);
  return (
    <Card className="pt-2 px-1 full-height">
      <div>
        <h6 className="chart-card__header">
          Temperature/ Dew Point
          <span className="title__recent-value">
            <span className="chart-card__header-value">
              {RecentValue.value !== null && RecentValue.value !== ""
                ? parseFloat(RecentValue.value).toFixed(1)
                : "_"}
            </span>{" "}
            &#176;C /{" "}
            <span className="chart-card__header-value">
              {RecentValueDew !== ""
                ? parseFloat(RecentValueDew).toFixed(1)
                : "_"}
            </span>
            &#176;C{" "}
            {RecentValueDew !== "" ? (
              indicator
            ) : (
              <Clock className="clock__icon" />
            )}
          </span>
        </h6>
      </div>
      <div className="chart-contianer">
        <Chart
          id="temp-chart"
          options={apex.options}
          series={apex.series}
          type="line"
          height="100%"
        />
      </div>
    </Card>
  );
}

TempChart.propTypes = {
  temps: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  tempsYes: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
  }).isRequired,
};

export default TempChart;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";

import "status-indicator/styles.css";
import moment from "moment";
import momenttz from "moment-timezone";

export default function CloudChart({ cloud, options, setDelay, settings }) {
  const cloudsData = [];
  const cloudLimit1 = [
    { x: parseInt(options.xaxis.min, 10), y: -23 },
    { x: parseInt(options.xaxis.max, 10), y: -23 },
  ];
  const cloudLimit2 = [
    { x: parseInt(options.xaxis.min, 10), y: -15 },
    { x: parseInt(options.xaxis.max, 10), y: -15 },
  ];

  if (cloud.data !== undefined) {
    cloud.data.forEach((item) => {
      cloudsData.push({ x: parseInt(item.readingDate, 10), y: item.clouds });
    });
  }

  const [apex, setApex] = useState({
    options: {
      ...options,
      chart: {
        ...options,
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      legend: {
        show: false,
      },
      colors: ["#19bcff", "#fb8500", "#d00000"],
      stroke: {
        ...options.stroke,
        dashArray: [0, 5, 5],
      },
      xaxis: {
        ...options.xaxis,
        tickAmount: 16,
      },
      yaxis: [
        {
          ...options.yaxis[0],
          title: {
            text: "Cloud cover",
            style: {
              fontSize: "14px",
            },
          },
          labels: {
            ...options.yaxis[0].labels,
            formatter(val) {
              return val.toFixed(1);
            },
          },
        },
      ],
    },
    series: [
      {
        data: [],
      },
    ],
  });
  const [RecentValue, setRecentValue] = useState({ value: 0, time: "" });

  let indicator;
  const duration = parseInt(
    moment
      .duration(
        moment(new Date(), "HH:mm:ss a").diff(
          moment(RecentValue.time, "HH:mm:ss a")
        )
      )
      .asMinutes() % 60,
    10
  );

  if (duration > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__cloud-1">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  } else {
    if (RecentValue.value < -10) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__cloud-2">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            positive
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (RecentValue.value > -10 && RecentValue.value < -5) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__cloud-3">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            pulse
            intermediary
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (RecentValue.value > -5) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__cloud-4">{RecentValue.time}</Tooltip>
          }
        >
          <status-indicator
            active
            negative
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
  }

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        {
          name: "Cloud",
          data: cloudsData,
        },
        {
          name: "Cloud Limit",
          data: cloudLimit1,
        },
        {
          name: "Cloud Limit2",
          data: cloudLimit2,
        },
      ],
    });

    setRecentValue(
      cloudsData[0] === undefined
        ? { value: undefined, time: 0 }
        : {
            value: cloudsData[cloudsData.length - 1].y,
            time: momenttz(new Date(cloudsData[cloudsData.length - 1].x * 1000))
              .tz(`${settings.station_timezone}`)
              .format("HH:mm"),
          }
    );
    setDelay(true);
  }, [cloud, options]);
  return (
    <div className="pt-0 px-0 full-height custom-card">
      <div className="recent-value">
        <h6 className="custom-card__title">
          <span>
            IR sky temp
            <span className="value white">
              {" "}
              {RecentValue.value === undefined
                ? ""
                : parseFloat(RecentValue.value).toFixed(1)}
            </span>{" "}
            &#176;C
          </span>
        </h6>
      </div>
      <Chart
        // id="cloud-chart"
        options={apex.options}
        series={apex.series}
        type="line"
        width="95%"
        height="250"
      />
    </div>
  );
}

CloudChart.propTypes = {
  cloud: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
    xaxis: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
      labels: PropTypes.shape({}),
    }),
  }).isRequired,
  setDelay: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    station_timezone: PropTypes.string,
  }).isRequired,
};

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";

import moment from "moment";
import momenttz from "moment-timezone";

export default function WindChart({ wind, windYes, options, settings }) {
  const [RecentValue, setRecentValue] = useState({ value: 0, time: "", lastReading: "" });
  const [RecentGustValue, setRecentGustValue] = useState(0);
  const [Average, setAverage] = useState(0);
  const windData = [];
  const windYesData = [];
  const gust = [];
  const gustYes = [];
  const windLimit1 = [
    { x: options.xaxis.min, y: 5 },
    { x: options.xaxis.max, y: 5 },
  ];
  const windLimit2 = [
    { x: options.xaxis.min, y: 20 },
    { x: options.xaxis.max, y: 20 },
  ];

  if (wind.data !== undefined && windYes.data !== undefined) {
    wind.data.forEach((item) => {
      windData.push({ x: parseInt(item.readingDate, 10), y: item.wind });
      gust.push({ x: parseInt(item.readingDate, 10), y: item.gust });
    });
    windYes.data.forEach((item) => {
      windYesData.push({
        x: parseInt(item.readingDate, 10) + 86400,
        y: item.wind,
      });
      gustYes.push({ x: parseInt(item.readingDate, 10) + 86400, y: item.gust });
    });
  }
  const [apex, setApex] = useState({
    options: {
      ...options,
      chart: {
        ...options.chart,
      },
      legend: {
        show: false,
      },
      markers: {
        size: [0, 0, 0, 0, 0],
      },
      fill: {
        type: "solid",
        pattern: {
          style: "squares",
        },
      },
      stroke: {
        ...options.stroke,
        dashArray: [0, 0, 0, 3, 3],
      },
      colors: ["#1B4253", "#F6FF00", "#00818A", "#fb8500", "#d00000"],
      yaxis: [
        {
          ...options.yaxis[0],
          max: 45,
          min: 0,
        },
      ],
    },
    series: [
      {
        data: [],
      },
    ],
  });

  let indicator;

  const isStaleData = (readingDate, stalePeriod, tag = '') => {
    const lastReading = moment.unix(readingDate).tz(settings.station_timezone).format("DD/MM/YY HH:mm:ss a").toString();
    const currentTime = moment(new Date()).tz(settings.station_timezone).format("DD/MM/YY HH:mm:ss a").toString();
    const readingInterval = moment.duration(
      moment(currentTime, "DD/MM/YY HH:mm:ss a")
        .diff(moment(lastReading, "DD/MM/YY HH:mm:ss a"))
    ).asMinutes();

    return (Math.floor(readingInterval) > stalePeriod);
  }

  const clockIndicator = (lastUpdated) => {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__cloud-1">
            Last updated {moment.unix(lastUpdated).tz(settings.station_timezone).format("DD-MMM HH:mm:ssa")}
          </Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  }

  if (isStaleData(RecentValue.lastReading, 15)) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__wind">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        {clockIndicator(RecentValue.lastReading)}
      </OverlayTrigger>
    );
  } else {
    if (parseInt(RecentValue.value, 10) >= 0 && RecentValue.value <= 5) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__wind">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            positive
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (
      parseInt(RecentValue.value, 10) >= 5 &&
      parseInt(RecentValue.value, 10) <= 20
    ) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__wind">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            intermediary
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (parseInt(RecentValue.value, 10) > 20) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__wind">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            negative
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
  }

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        {
          name: "Yesterday",
          type: "line",
          data: windYesData,
        },
        {
          name: "Gust",
          type: "line",
          data: gust,
        },
        {
          name: "Wind",
          type: "line",
          data: windData,
        },
        {
          name: "wind Limit",
          type: "line",
          data: windLimit1,
        },
        {
          name: "wind Limit",
          type: "line",
          data: windLimit2,
        },
      ],
    });

    setRecentValue(
      windData[0] === undefined
        ? { ...RecentValue }
        : {
          value: windData[windData.length - 1].y,
          time: momenttz(new Date(windData[windData.length - 1].x * 1000))
            .tz(`${settings.station_timezone}`)
            .format("HH:mm"),
          lastReading: windData[windData.length - 1].x,
        }
    );
    setRecentGustValue(gust[0] === undefined ? "" : gust[gust.length - 1].y);
    setAverage(
      windData[0] === undefined ||
        windData[0] === null ||
        windData[0].y === null
        ? ""
        : windData.reduce((a, b) => a + parseInt(b.y, 10), 0) / windData.length
    );
  }, [wind, windYes, options]);
  return (
    <Card className="pt-2 px-1 full-height">
      <div>
        <h6 className="chart-card__header">
          Wind
          <span className="title__recent-value">
            average:{" "}
            <span className="chart-card__header-value">
              {Average !== "" ? parseFloat(Average).toFixed(1) : " _ "}
            </span>
            km/h gust:{" "}
            <span className="chart-card__header-value">
              {RecentGustValue !== "" || RecentGustValue !== null
                ? parseFloat(RecentGustValue).toFixed(1)
                : " _ "}
            </span>
            km/h
            {RecentGustValue !== "" || RecentGustValue !== null ? (
              indicator
            ) : (
              <Clock className="clock__icon" />
            )}
          </span>
        </h6>
      </div>
      <div className="chart-contianer">
        <Chart
          id="wind-chart"
          options={apex.options}
          series={apex.series}
          type="line"
          height="100%"
        />
      </div>
    </Card>
  );
}

WindChart.propTypes = {
  wind: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  windYes: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
  }).isRequired,
};

/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement } from "react";

import { Container, Row, Col, Card, Image } from "react-bootstrap";
import Footer from "./Footer";
import SolarWidget from "./shared/SolarWidget";

export default function Solar(): ReactElement {
  return (
    <div>
      <Container fluid>
        <Row
          className="mx-1 custom__row gy-2"
          style={{
            color: "white",
            height: "calc(100vh - 124px)",
          }}
        >
          <Col md={6} style={{ height: "100%" }}>
            <Col md={12} style={{ height: "35%" }}>
              <Card className="pb-1 pt-1 px-1" style={{ height: "100%" }}>
                <h6 className="h6">SDO 48 hour time lapse</h6>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    height: "90%",
                  }}
                >
                  <video
                    title="Earth-ISS"
                    src="https://sdo.gsfc.nasa.gov/assets/img/latest/mpeg/latest_512_0193.mp4#505497"
                    width="400"
                    controls
                    style={{ height: "100%" }}
                  />
                  <video
                    title="Earth-ISS"
                    src="https://sdo.gsfc.nasa.gov/assets/img/latest/mpeg/latest_512_0304.mp4#813394"
                    width="400"
                    controls
                    style={{ height: "100%" }}
                  />

                  <video
                    title="Earth-ISS"
                    src="https://sdo.gsfc.nasa.gov/assets/img/latest/mpeg/latest_512_0171.mp4#982586"
                    width="400"
                    controls
                    style={{ height: "100%" }}
                  />
                </div>
              </Card>
            </Col>

            <Row className="my-2" style={{ height: "65%" }}>
              <Col md={6} style={{ height: "100%" }}>
                <Card className="pb-1 pt-1 px-1">
                  <Card.Body className="p-0">
                    <Card.Title className="h6">
                      Coronal holes and active regions
                    </Card.Title>
                    <Image
                      style={{ width: "100%" }}
                      src="https://www.solen.info/solar/images/charmap512.jpg"
                      alt=""
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} style={{ height: "100%" }}>
                <Col sm={12}>
                  <Card className="pb-1 pt-1 px-1" style={{ height: "100%" }}>
                    <h6> Current /Forecast Solar Activity</h6>
                    <Image
                      title="Forecast Solar Activity"
                      src="https://www.sws.bom.gov.au/Images/Solar/Solar%20Conditions/Solar%20Activity%20Plot/solplot.gif"
                    />
                  </Card>
                </Col>
                <Col className="mt-1" sm={12}>
                  <Card className="pb-1 pt-1 px-1">
                    <h6>Sunspot Analysis</h6>
                    <SolarWidget
                      details={{
                        radios: [
                          { name: "Daily", value: "0" },
                          { name: "Monthly", value: "1" },
                          { name: "Yearly", value: "2" },
                          { name: "Current year", value: "3" },
                          { name: "Forecast", value: "4" },
                        ],
                        src: [
                          `https://www.sidc.be/images/wolfjmms.png`,
                          `https://wwwbis.sidc.be/images/wolfmms.png`,
                          `https://wwwbis.sidc.be/silso/IMAGES/GRAPHICS/wolfaml2.png`,
                          `https://wwwbis.sidc.be/silso/DATA/EISN/EISNcurrent.png`,
                          `https://wwwbis.sidc.be/silso/IMAGES/GRAPHICS/prediKFSC.png`,
                        ],
                        type: "button",
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Card>
                </Col>
              </Col>
            </Row>
          </Col>
          <Col md={6} style={{ height: "100%" }}>
            <Card className="pb-1 pt-1 px-1" style={{ height: "100%" }}>
              <h6
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Solar Dynamics Observatory current image
                <a href="/" className="custom-btn__circular minus">
                  {" "}
                </a>
              </h6>
              <SolarWidget
                details={{
                  radios: [
                    {
                      name:
                        "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_0171.jpg",
                      value: "0",
                    },
                    {
                      name:
                        "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_512_0193.jpg",
                      value: "1",
                    },
                    {
                      name:
                        "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_0304.jpg",
                      value: "2",
                    },
                    {
                      name:
                        "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_HMIBC.jpg",
                      value: "4",
                    },
                    {
                      name:
                        "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_1600.jpg",
                      value: "3",
                    },
                    {
                      name:
                        "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_0211.jpg",
                      value: "5",
                    },
                    {
                      name:
                        "https://sdo.gsfc.nasa.gov/assets/img/latest/f_304_211_171_2048.jpg",
                      value: "6",
                    },
                    {
                      name:
                        "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_1024_211193171.jpg",
                      value: "7",
                    },
                    {
                      name:
                        "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_HMIIC.jpg",
                      value: "8",
                    },
                  ],
                  src: [
                    "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_0171.jpg",
                    "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_512_0193.jpg",
                    "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_0304.jpg",
                    "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_HMIBC.jpg",
                    "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_1600.jpg",
                    "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_0211.jpg",
                    "https://sdo.gsfc.nasa.gov/assets/img/latest/f_304_211_171_2048.jpg",
                    "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_1024_211193171.jpg",
                    "https://sdo.gsfc.nasa.gov/assets/img/latest/latest_2048_HMIIC.jpg",
                  ],
                  type: "image",
                }}
                style={{ height: "100%", margin: "auto" }}
              />
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

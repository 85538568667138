import React, { ReactElement } from "react";

import { Container, Row, Col, Card, ResponsiveEmbed } from "react-bootstrap";
import { Timeline } from "react-twitter-widgets";
import UpcomingDataTable from "./shared/UpcomingDataTable";
import Footer from "./Footer";
import { SettingsContext } from "../hooks/settings-context";

export default function Iss(): ReactElement {
  return (
    <SettingsContext.Consumer>
      {({ apiEndpoint }) => (
        <div>
          <Container fluid>
            <Row
              className="mx-1 custom__row gy-2"
              style={{
                color: "white",
                height: "calc(100vh - 130px)",
              }}
            >
              <Col md={6} className="full-height">
                <Row className="gy-5 full-height">
                  <Col className="full-height">
                    <Card className="p-1">
                      <>
                        <h6>Twitter Feed</h6>
                      </>
                      <Timeline
                        dataSource={{
                          sourceType: "profile",
                          screenName: "Space_Station",
                        }}
                        options={{
                          chrome: "noheader, nofooter",
                          height: "calc(100vh - 177px)",
                        }}
                      />
                    </Card>
                  </Col>
                  <Col className="full-height">
                    <Card className="pb-1 pt-1 px-1 full-height">
                      <h6 className="h6">Earth From ISS</h6>
                      <div className="full-height">
                        <ResponsiveEmbed
                          className="embed-responsive-16by9"
                          style={{ height: "48%" }}
                        >
                          <iframe
                            title="lastHour"
                            src="https://ustream.tv/embed/17074538?html5ui=1&autoplay=true"
                            width="100%"
                          />
                        </ResponsiveEmbed>
                        <ResponsiveEmbed
                          className="embed-responsive-16by9 mt-2"
                          style={{ height: "48%" }}
                        >
                          <iframe
                            title="lastHour"
                            src="https://ustream.tv/embed/9408562?html5ui=1&autoplay=true"
                            height="100%"
                          />
                        </ResponsiveEmbed>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md={6} style={{ height: "50%" }}>
                <Card
                  className="pb-1 pt-1 px-1"
                  style={{
                    height: "100%",
                  }}
                >
                  <>
                    <h6
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      ISS Tracker
                      <a href="/" className="custom-btn__circular minus">
                        {" "}
                      </a>
                    </h6>
                  </>
                  <iframe
                    title="iss-tracker"
                    src="https://isstracker.pl/en/widget/map?&disableInfoBox=1&lang=en&z=2&mapType=satellite&units=metric&preloader=0&showSatTooltip=0&dayNightLayer=1&showFutureOrbit=1"
                    className="full-height"
                  />
                </Card>
                <Row className="my-2" style={{ height: "99%" }}>
                  <Col className="full-height">
                    <Card className="pb-1 pt-1 px-1 full-height">
                      <h6 className="h6">Upcoming Passes</h6>
                      <UpcomingDataTable
                        apiEndpoint={apiEndpoint}
                        maxHeight="100%"
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      )}
    </SettingsContext.Consumer>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";

import momenttz from "moment-timezone";
import moment from "moment";

export default function SqmChart({ options, sqm, settings }) {
  const [apex, setApex] = useState({
    options: {
      ...options,
      colors: ["#F6AE2D", "#4B70FF", "#F6AE2D55"],
      yaxis: [
        {
          ...options.yaxis[0],
          seriesName: "mpsas",
          min: 0,
          max: 22,
          labels: {
            ...options.yaxis[0].labels,
            formatter(val) {
              return val.toFixed(0);
            },
          },
          title: {
            text: "SQM Darkness",
            style: {
              fontSize: "12px",
            },
          },
        },
        {
          ...options.yaxis[0],
          seriesName: "lunar",
          opposite: true,
          max: 90,
          min: 0,
          tickAmount: 6,
          labels: {
            ...options.yaxis[0].labels,
            formatter(val) {
              return parseFloat(val).toFixed(0);
            },
          },
        },
        {
          ...options.yaxis[0],
          seriesName: "Yesterday",
          min: 0,
          max: 22,
          formatter(val) {
            return val.toFixed(0);
          },
          show: false,
        },
      ],
    },
    series: [
      {
        name: "Mag/Sq ArcSec",
        data: [],
      },
      {
        name: "Moon altitude",
        data: [],
      },
    ],
  });

  const [RecentValue, setRecentValue] = useState({ value: 0, time: "" });
  const [RecentValueLunar, setRecentValueLunar] = useState(0);

  let indicator;
  const duration = parseInt(
    moment
      .duration(
        moment(new Date(), "HH:mm:ss a").diff(
          moment(RecentValue.time, "HH:mm:ss a")
        )
      )
      .asMinutes() % 60,
    10
  );
  if (duration > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip">Last updated {RecentValue.time}</Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  } else {
    if (parseInt(RecentValue.value, 10) < 7) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__sqm">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            negative
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (
      parseInt(RecentValue.value, 10) >= 7 &&
      parseInt(RecentValue.value, 10) <= 15
    ) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__sqm">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            intermediary
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (parseInt(RecentValue.value, 10) > 15) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__sqm">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            positive
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
  }

  const mpsas = [];
  const lunarAlt = [];

  if (sqm.data !== undefined) {
    sqm.data.forEach((item) => {
      mpsas.push({ x: parseInt(item.readingDate, 10), y: item.mpsas });
      lunarAlt.push({ x: parseInt(item.readingDate, 10), y: item.lunarAlt });
    });
  }
  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        {
          name: "mpsas",
          data: mpsas,
        },
        {
          name: "lunar",
          data: lunarAlt,
        },
      ],
    });
    setRecentValue(
      mpsas[0] === undefined
        ? { value: "", time: "" }
        : {
            value: mpsas[mpsas.length - 1].y,
            time: momenttz(new Date(mpsas[mpsas.length - 1].x * 1000))
              .tz(`${settings.station_timezone}`)
              .format("HH:mm"),
          }
    );
    setRecentValueLunar(
      lunarAlt[lunarAlt.length - 1] === undefined
        ? ""
        : lunarAlt[lunarAlt.length - 1].y
    );
  }, [sqm]);
  return (
    <div className="full-height custom-card">
      <div className="recent-value">
        <h6 className="h6 custom-card__title">
          <span>
            Moon{" "}
            <span className="value">
              {RecentValueLunar !== "" ? RecentValueLunar : "_"}
            </span>
            &#176; , mpsas{" "}
            <span className="value">
              {RecentValue.value !== ""
                ? parseFloat(RecentValue.value).toFixed(1)
                : "_"}
            </span>
          </span>
        </h6>
      </div>
      <Chart
        id="sqm-chart"
        options={apex.options}
        series={apex.series}
        type="line"
        height="250"
      />
    </div>
  );
}

SqmChart.propTypes = {
  sqm: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
  }).isRequired,
  settings: PropTypes.shape({
    station_timezone: PropTypes.string,
  }).isRequired,
};

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";
import moment from "moment";
import momenttz from "moment-timezone";

import "status-indicator/styles.css";
import { yellow } from "@material-ui/core/colors";

export default function CloudChart({
  cloud,
  cloudYes,
  weatherSafety,
  options,
  setDelay,
  settings,
}) {
  const [cloudsData, setCloudsData] = useState([]);
  const [cloudYesData, setCloudYesData] = useState([]);

  const cloudLimit1 = [
    { x: options.xaxis.min, y: -23 },
    { x: options.xaxis.max, y: -23 },
  ];
  const cloudLimit2 = [
    { x: options.xaxis.min, y: -15 },
    { x: options.xaxis.max, y: -15 },
  ];

  const [weatherSafetyData, setWeatherSafetyData] = useState([])

  useEffect(() => {
    const latestCloudData = [];
    const latestCloudYesData = [];
    cloud.data?.forEach((item) => {
      latestCloudData.push({ x: parseInt(item.readingDate, 10), y: item.clouds });
    });

    cloudYes.data?.forEach((item) => {
      latestCloudYesData.push({
        x: parseInt(item.readingDate, 10) + 86400,
        y: item.clouds,
      });
    });

    setCloudsData(latestCloudData);
    setCloudYesData(latestCloudYesData);
  }, [cloud.data, cloudYes.data]);

  useEffect(() => {
    const latestWeatherSafetyData = [];
    weatherSafety.data?.forEach((item) => {
      latestWeatherSafetyData.push({
        x: parseInt(item.timestamp, 10),
        y: parseInt(item.flag, 10) === 1 ? 0 : 1,
      });
    });
    setWeatherSafetyData(latestWeatherSafetyData);
  }, [weatherSafety.data]);

  const [apex, setApex] = useState({
    options: {
      ...options,
      chart: {
        ...options,
        stacked: false,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      colors: ["#19bcff55", "#19bcff", "#fb8500", "#d00000"],
      stroke: {
        ...options.stroke,
        dashArray: [0, 0, 3, 3],
      },
      yaxis: [
        {
          ...options.yaxis[0],
          labels: {
            ...options.yaxis[0].labels,
            formatter(val) {
              return val?.toFixed(1);
            },
          },
        },
        {
          show: false
        },
        {
          show: false
        },
      ],
    },
    series: [
      {
        data: [],
      },
    ],
  });
  const [RecentValue, setRecentValue] = useState({ value: 0, time: "", lastReading: "" });

  let indicator;

  const isStaleData = (readingDate, stalePeriod, tag = '') => {
    const lastReading = moment.unix(readingDate).tz(settings.station_timezone).format("DD/MM/YY HH:mm:ss a").toString();
    const currentTime = moment(new Date()).tz(settings.station_timezone).format("DD/MM/YY HH:mm:ss a").toString();
    const readingInterval = moment.duration(
      moment(currentTime, "DD/MM/YY HH:mm:ss a")
        .diff(moment(lastReading, "DD/MM/YY HH:mm:ss a"))
    ).asMinutes();

    return (Math.floor(readingInterval) > stalePeriod);
  }

  const clockIndicator = (lastUpdated) => {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__cloud-1">
            Last updated {moment.unix(lastUpdated).tz(settings.station_timezone).format("DD-MMM HH:mm:ssa")}
          </Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  }

  if (isStaleData(RecentValue.lastReading, 15)) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__cloud-1">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        {clockIndicator(RecentValue.lastReading)}
      </OverlayTrigger>
    );
  } else {
    if (RecentValue.value < -10) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__cloud-2">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            positive
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (RecentValue.value > -10 && RecentValue.value < -5) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__cloud-3">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            pulse
            intermediary
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (RecentValue.value > -5) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__cloud-4">{RecentValue.time}</Tooltip>
          }
        >
          <status-indicator
            active
            negative
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
  }

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        {
          name: "Yesterday",
          type: 'line',
          data: cloudYesData,
        },
        {
          name: "Cloud",
          type: 'line',
          data: cloudsData,
        },
        {
          name: "Lower Cloud Limit",
          type: 'line',
          data: cloudLimit1,
        },
        {
          name: "Upper Cloud Limit",
          type: 'line',
          data: cloudLimit2,
        },
        {
          name: 'Roof Status',
          type: 'area',
          data: weatherSafetyData,
        },
      ],
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },
    });

    setRecentValue(
      cloudsData[0] === undefined
        ? { value: undefined, time: 0 }
        : {
          value: cloudsData[cloudsData.length - 1].y,
          time: momenttz(new Date(cloudsData[cloudsData.length - 1].x * 1000))
            .tz(settings.station_timezone)
            .format("HH:mm"),
          lastReading: cloudsData[cloudsData.length - 1].x,
        }
    );
    setDelay(true);
  }, [cloud, cloudYes, weatherSafetyData, options]);

  return (
    <Card className="pt-2 px-1 full-height">
      <div className="p-0">
        <h6 className="chart-card__header">
          Cloud Cover / Roof Status
          <span className="title__recent-value">
            IR sky temp
            <span className="chart-card__header-value">
              {" "}
              {RecentValue.value === undefined || RecentValue.value === null
                ? "_"
                : parseFloat(RecentValue.value).toFixed(1)}
            </span>{" "}
            &#176;C
            {indicator}
          </span>
        </h6>
      </div>
      <div className="chart-contianer">
        <Chart
          // id="cloud-chart"
          options={apex.options}
          series={apex.series}
          type="line"
          height="100%"
        />
      </div>
    </Card>
  );
}

CloudChart.propTypes = {
  cloud: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  cloudYes: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape([]),
  }).isRequired,
  setDelay: PropTypes.func.isRequired,
};

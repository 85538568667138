/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";
import moment from "moment";
import momenttz from "moment-timezone";

// eslint-disable-next-line react/prop-types
export default function AlldylosChart({alldylos, alldylosYes, options, settings, setSeeingHeader, showSeeingHeader}) {
  const small = [];
  const smallYes = [];

  const large = [];
  const largeYes = [];
  if (alldylos.data !== undefined && alldylosYes.data !== undefined) {
    alldylos.data.forEach((item) => {
      small.push({ x: parseInt(item.readingDate, 10), y: item.small });
      large.push({ x: parseInt(item.readingDate, 10), y: item.large });
    });
    alldylosYes.data.forEach((item) => {
      smallYes.push({
        x: parseInt(item.readingDate, 10) + 86400,
        y: item.small,
      });
      largeYes.push({
        x: parseInt(item.readingDate, 10) + 86400,
        y: item.large,
      });
    });
  }
  const [apex, setApex] = useState({
    options: {
      ...options,
      colors: ["#F6AE2D55", "#F6AE2D", "#FF837355", "#FF8373"],
      legend: {
        show: true,
        labels: {
          colors: "#fff",
        },
      },
    },
    series: [
      {
        name: ">2.5um",
        data: [],
      },
      {
        name: "Yesterday",
        data: [],
      },
      {
        name: ">10um",
        data: [],
      },
      {
        name: "Yesterday",
        data: [],
      },
    ],
  });

  const [RecentValue, setRecentValue] = useState({ value: 0, time: "" });
  const [RecentValueLarge, setRecentValueLarge] = useState(0);
  let indicator;
  const duration = parseInt(
    moment
      .duration(
        moment(new Date(), "HH:mm:ss a").diff(
          moment(RecentValue.time, "HH:mm:ss a")
        )
      )
      .asMinutes() % 60,
    10
  );
  if (duration > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__dylos">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  } else if (parseInt(RecentValue, 10) > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__dylo">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <status-indicator active pulse style={{ margin: "0 10px" }} />
      </OverlayTrigger>
    );
  } else {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__dylos">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <status-indicator active pulse positive style={{ margin: "0 10px" }} />
      </OverlayTrigger>
    );
  }

  useEffect(() => {
    setApex({
      ...apex,
      series: [
        {
          name: "Yesterday",
          data: smallYes,
        },
        {
          name: ">2.5um",
          data: small,
        },
        {
          name: "Yesterday",
          data: largeYes,
        },

        {
          name: ">10um",
          data: large,
        },
      ],
    });

    setRecentValue(
      small[0] === undefined
        ? { value: "", time: 0 }
        : {
            value: small[small.length - 1].y,
            time: momenttz(new Date(small[small.length - 1].x * 1000))
              .tz(`${settings.station_timezone}`)
              .format("HH:mm"),
          }
    );
    setRecentValueLarge(
      large[0] === undefined ? "" : large[large.length - 1].y
    );
  }, [alldylos, alldylosYes]);

  useEffect(() => {
    setSeeingHeader(() => (
      <div>
        {
          showSeeingHeader &&
          <span className="title__recent-value">
            {" "}
            &gt;2.5&mu;m{" "}
            <span className="chart-card__header-value">
              {RecentValue.value !== ""
                ? parseFloat(RecentValue.value).toFixed(1)
                : "_"}
            </span>{" "}
            &gt;10&mu;m{" "}
            <span className="chart-card__header-value">
              {RecentValueLarge !== ""
                ? parseFloat(RecentValueLarge).toFixed(1)
                : "_"}
            </span>{" "}
            {RecentValue.value === "" ? (
              <Clock className="clock__icon" />
            ) : (
              indicator 
            )}
          </span>
        }
      </div>
    ))
  }, [])
  return ( 
    <Chart
      id="dylos-chart"
      options={apex.options}
      series={apex.series}
      type="line"
      height="100%"
    />
  );
}

AlldylosChart.propTypes = {
  alldylos: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  alldylosYes: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
  }).isRequired,
  settings: PropTypes.shape({
    station_timezone: PropTypes.string,
  }).isRequired,
};

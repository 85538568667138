/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";

import "status-indicator/styles.css";
import moment from "moment";
import momenttz from "moment-timezone";
// import { from, to } from "../intervals";

export default function RainChart({ rain, options, settings }) {
  const [RecentValue, setRecentValue] = useState({ value: 0, time: "" });
  const [RecentValueHumidity, setRecentValueHumidity] = useState(0);

  const humdity = [];
  const rainData = [];
  const rainLimit1 = [
    { x: parseInt(options.xaxis.min, 10), y: 90 },
    { x: parseInt(options.xaxis.max, 10), y: 90 },
  ];
  const rainLimit2 = [
    { x: parseInt(options.xaxis.min, 10), y: 95 },
    { x: parseInt(options.xaxis.max, 10), y: 95 },
  ];

  if (rain.data !== undefined) {
    rain.data.forEach((item) => {
      rainData.push({ x: parseInt(item.readingDate, 10), y: item.rain });
      humdity.push({ x: parseInt(item.readingDate, 10), y: item.humidity });
      rainLimit1.push({ x: parseInt(item.readingDate, 10), y: 90 });
      rainLimit2.push({ x: parseInt(item.readingDate, 10), y: 95 });
    });
  }
  const [apex, setApex] = useState({
    options: {
      ...options,
      colors: ["#D93025", "#534DF8", "#fb8500", "#AB271D"],
      stroke: {
        ...options.stroke,
        dashArray: [0, 0, 3, 3],
      },
      xaxis: {
        ...options.xaxis,
        tickAmount: 16,
        labels: {
          ...options.xaxis.labels,
          show: true,
        },
      },
      yaxis: [
        {
          ...options.yaxis[0],
          seriesName: "humidity",
          min: 0,
          max: 100,
          tickAmount: 4,
          title: {
            text: "Relative Humidity / Rain",
            style: {
              fontSize: "12px",
            },
          },
        },
        {
          ...options.yaxis[0],
          seriesName: "rain",
          opposite: true,
          reversed: true,
          min: 0,
          max: 4300,
        },
        {
          ...options.yaxis[0],
          seriesName: "Rain Limit",
          show: false,
          min: 0,
          max: 100,
        },
        {
          ...options.yaxis[0],
          seriesName: "Rain Limit",
          show: false,
          min: 0,
          max: 100,
        },
      ],
    },
    series: [{ name: "mm", data: [] }],
  });

  let indicator;
  const duration = parseInt(
    moment
      .duration(
        moment(new Date(), "HH:mm:ss a").diff(
          moment(RecentValue.time, "HH:mm:ss a")
        )
      )
      .asMinutes() % 60,
    10
  );

  if (duration > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__rain">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  } else {
    if (parseInt(RecentValueHumidity, 10) > 95) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__rain">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            negative
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (parseInt(RecentValueHumidity, 10) > 90) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__rain">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator active intermediary style={{ margin: "0 10px" }} />
        </OverlayTrigger>
      );
    }
    if (parseInt(RecentValueHumidity, 10) < 90) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__rain">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            positive
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
  }

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        {
          name: "Humdity (L)",
          data: humdity,
        },
        {
          name: "Rain Sensor (R)",
          data: rainData,
        },
        {
          name: "Limit 1",
          data: rainLimit1,
        },
        {
          name: "Limit 2",
          data: rainLimit2,
        },
      ],
    });

    setRecentValue(
      rainData[0] === undefined
        ? { value: "", time: "" }
        : {
            value: rainData[rainData.length - 1].y,
            time: momenttz(new Date(rainData[rainData.length - 1].x * 1000))
              .tz(`${settings.station_timezone}`)
              .format("HH:mm"),
          }
    );
    setRecentValueHumidity(
      humdity[0] === undefined ? "" : humdity[humdity.length - 1].y
    );
  }, [rain]);
  return (
    <div className="pt-0 full-height custom-card">
      <div
        style={{
          position: "absolute",
          top: "35px",
          left: "90%",
        }}
      >
        <h6 className="h6 custom-card__title">
          <span>
            RH:
            <span className="value">
              {RecentValueHumidity === "" ? "_" : RecentValueHumidity}
            </span>
            %
          </span>
        </h6>
      </div>
      <Chart
        id="weekly-rain-chart"
        options={apex.options}
        series={apex.series}
        type="line"
        height="250"
      />
    </div>
  );
}

RainChart.propTypes = {
  rain: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
    xaxis: PropTypes.shape({ labels: PropTypes.shape({}) }),
  }).isRequired,
  settings: PropTypes.shape({
    station_timezone: PropTypes.string,
  }).isRequired,
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Table, Card } from "react-bootstrap";
import moment from "moment";
import {
  Reception4,
  Reception3,
  Reception2,
  Reception1,
} from "react-bootstrap-icons";
import Countdown from "react-countdown";
import dataFetch from "../../actions/fetchDataActions";

type TableProps = {
  apiEndpoint: string,
};

const UpcomingMinDataTable = ({ apiEndpoint }: TableProps) => {
  const [TableData, setTableData] = useState({
    items: {
      data: {},
    },
  });
  const getIcon = (mag) => {
    if (mag < -3) {
      return <Reception4 className="status__indicator" color="green" />;
    }
    if (mag < -2) {
      return <Reception3 className="status__indicator" color="green" />;
    }
    if (mag < -1) {
      return <Reception2 className="status__indicator" color="orange" />;
    }
    if (mag > -1) {
      return <Reception1 className="status__indicator" color="red" />;
    }
    return "";
  };

  const getElevationIcon = (el) => {
    if (el >= 40) {
      return <Reception4 className="status__indicator" color="green" />;
    }
    if (el >= 20 && el < 40) {
      return <Reception2 className="status__indicator" color="orange" />;
    }
    if (el < 20) {
      return <Reception1 className="status__indicator" color="red" />;
    }
    return "";
  };

  useEffect(() => {
    const upcomingDataFetch = () => {
      dataFetch(`${apiEndpoint}/data/iss/passes`)
        .then((data) => {
          if (data.error === null) {
            setTableData({
              items: {
                data: {
                  passes: [
                    data.items.data.passes[0],
                    data.items.data.passes[1],
                  ],
                },
              },
            });
          }
        })
        .catch((e) => {
          /* eslint-disable no-console */
          console.log(e);
          /* eslint-enable no-console */
        });
    };

    upcomingDataFetch();
  }, [apiEndpoint]);

  return (
    <Card className="pb-1 pt-1 px-1 custom-card full-height">
      {TableData.items.data.passes ? (
        <>
          <h6 className="custom-card__title">
            ISS Passes
            {TableData.items.data.passes[0] !== undefined ? (
              <span className="title__recent-value">
                <span>
                  <Countdown
                    date={TableData.items.data.passes[0].startUTC * 1000}
                  />
                </span>
                <span className="space-x-10">
                  {parseInt(
                    moment
                      .duration(
                        moment(
                          moment(
                            TableData.items.data.passes[0].startUTC * 1000
                          ).diff(new Date())
                        )
                      )
                      .asHours(),
                    10
                  ) < 24 ? (
                    <status-indicator active positive pulse />
                  ) : (
                    <status-indicator active intermediary pulse />
                  )}
                </span>
              </span>
            ) : (
              ""
            )}
          </h6>
          <div className="minTable-wrapper">
            <Table bordered hover variant="dark" bsPrefix="custom-table__mini">
              <thead>
                <tr>
                  <td>Date </td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Duration</td>
                  <td>Elevation</td>
                  <td> </td>
                  <td>Magnitude</td>
                  <td> </td>
                </tr>
              </thead>
              <tbody>
                {TableData.items.data.passes.map((row, key) =>
                  row !== undefined ? (
                    <tr key={key}>
                      <td>
                        {moment(row?.startUTC * 1000).format("ddd DD MMM")}
                      </td>
                      <td>{moment(row?.startUTC * 1000).format("HH:mm")}</td>
                      <td>{moment(row?.endUTC * 1000).format("HH:mm")}</td>
                      <td>
                        {new Date(row.duration * 1000)
                          .toISOString()
                          .substr(15, 4)}
                      </td>
                      <td>{row.maxEl}&deg;</td>
                      <td>{getElevationIcon(row.maxEl)}</td>
                      <td>{row.mag}</td>
                      <td>{getIcon(row.mag)}</td>
                    </tr>
                  ) : (
                    ""
                  )
                )}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <>
          <h6 className="custom-card__title">ISS passes</h6>
          <div>
            <p className="text-white"> No Data For Now </p>
          </div>
        </>
      )}
    </Card>
  );
};

export default UpcomingMinDataTable;

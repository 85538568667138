import React, { ReactElement } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DefaultLayout from "./Layouts/DefaultLayout";
import ScienceLayout from "./Layouts/ScienceLayout";
import Footer from "./Footer";
import ChartWapper from "./shared/charts/ChartWapper";
import { SettingsContext } from "../hooks/settings-context";

function Monitor(): ReactElement {
  return (
    <SettingsContext.Consumer>
      {({ apiEndpoint, data }) => (
        <>
          <Container fluid>
            <Row className="custom__row">
              <Col sm={6} className="full-height">
                {data.station_timezone && (
                  <ChartWapper
                    apiEndpoint={apiEndpoint}
                    settings={{
                      station_timezone: data.station_timezone,
                      voyager_feed: data.voyager_feed,
                    }}
                  />
                )}
              </Col>
              {data && data.app_layout === "default"
                ? data && (
                  <DefaultLayout
                    settings={{
                      station_lat: data.station_lat,
                      station_long: data.station_long,
                      station_timezone: data.station_timezone,
                      weather_station: data.weather_station,
                      allsky_last_image: data.allsky_last_image,
                      science_last_image: data.science_last_image,
                      science_last_graph: data.science_last_graph,
                    }}
                  />
                )
                : data && (
                  <ScienceLayout
                    settings={{
                      apiEndpoint,
                      voyager_feed: data.voyager_feed,
                      station_lat: data.station_lat,
                      station_long: data.station_long,
                      station_timezone: data.station_timezone,
                      weather_station: data.weather_station,
                      allsky_last_image: data.allsky_last_image,
                      science_last_image: data.science_last_image,
                      science_last_graph: data.science_last_graph,
                      scopes: JSON.parse(data.scopes || "{}"),
                    }}
                  />
                )}
            </Row>
          </Container>
          <Footer />
        </>
      )}
    </SettingsContext.Consumer>
  );
}

export default Monitor;

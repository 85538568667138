/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";

import { Clock } from "react-bootstrap-icons";
import moment from "moment";
import momenttz from "moment-timezone";

export default function NsmChart({ nsm, options, settings }) {
  const [apex, setApex] = useState({
    options: {
      ...options,
      colors: ["#A4A1FB", "#56D9FE", "#fb8500", "#d00000"],
      stroke: {
        ...options.stroke,
        dashArray: [0, 0, 5, 5],
      },
      xaxis: {
        ...options.xaxis,
        tickAmount: 16,
      },
      yaxis: [
        {
          ...options.yaxis,
          title: {
            text: "Night Seeing Monitor",
            style: {
              ...options.yaxis.style,
              fontSize: "12px",
            },
          },
        },
      ],
    },
    series: [],
  });
  const [RecentValue, setRecentValue] = useState({ value: 0, time: "" });
  const CorrectedFWHM = [];
  const UncorrectedFWHM = [];
  const correctedLimit1 = [
    { x: options.xaxis.min, y: "1.5" },
    { x: options.xaxis.max, y: "1.5" },
  ];
  const correctedLimit2 = [
    { x: options.xaxis.min, y: "3.5" },
    { x: options.xaxis.max, y: "3.5" },
  ];

  let indicator;
  const duration = parseInt(
    moment
      .duration(
        moment(new Date(), "HH:mm:ss a").diff(
          moment(RecentValue.time, "HH:mm:ss a")
        )
      )
      .asMinutes() % 60,
    10
  );
  if (duration > 15) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__nsm">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        &quot; <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  } else {
    if (
      parseInt(RecentValue.value, 10) >= 0 &&
      parseInt(RecentValue.value, 10) <= 1.5
    ) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__nsm">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            positive
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (
      parseInt(RecentValue.value, 10) >= 1.5 &&
      parseInt(RecentValue.value, 10) <= 3
    ) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__nsm">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            Intermediary
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (parseInt(RecentValue.value, 10) > 3) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__nsm">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            negative
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
  }

  if (nsm.data !== undefined) {
    nsm.data.forEach((item) => {
      CorrectedFWHM.push({
        x: parseInt(item.readingDate, 10),
        y: parseFloat(item.corrected).toFixed(1),
      });
      UncorrectedFWHM.push({
        x: parseInt(item.readingDate, 10),
        y: parseFloat(item.uncorrected).toFixed(2),
      });
    });
  }

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        {
          name: "Corrected FWHM",
          data: CorrectedFWHM,
        },
        {
          name: "Uncorrected FWHM",
          data: UncorrectedFWHM,
        },
        {
          name: "limit1",
          data: correctedLimit1,
        },
        {
          name: "limit2",
          data: correctedLimit2,
        },
      ],
    });
    setRecentValue(
      CorrectedFWHM[0] === undefined
        ? { value: "", time: "" }
        : {
            value: CorrectedFWHM[CorrectedFWHM.length - 1].y,
            time: momenttz(
              new Date(CorrectedFWHM[CorrectedFWHM.length - 1].x * 1000)
            )
              .tz(`${settings.station_timezone}`)
              .format("HH:mm:ss a"),
          }
    );
  }, [nsm]);
  return (
    <div className="full-height custom-card">
      <div className="recent-value">
        <h6 className="custom-card__title">
          <span>
            Seeing:{" "}
            <span className="value" style={{ color: "white" }}>
              {RecentValue.value === "" || NaN
                ? "_"
                : parseFloat(RecentValue.value).toFixed(1)}{" "}
              &quot;
            </span>
          </span>
        </h6>
      </div>
      <div className="full-height">
        <Chart
          id="nsm-chart"
          options={apex.options}
          series={apex.series}
          type="line"
          width="95%"
          height="250"
        />
      </div>
      <span style={{ color: "white", fontSize: "0.6rem" }}>
        Long Exposure Seeing in ArcSec
      </span>
    </div>
  );
}

NsmChart.propTypes = {
  nsm: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
  }).isRequired,
  settings: PropTypes.shape({
    station_timezone: PropTypes.string,
  }).isRequired,
};

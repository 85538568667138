import React, { ReactElement, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Col, Row } from "react-bootstrap";
import dataFetch from "../../../actions/fetchDataActions";

type ChartProps = {
    apiEndpoint: string,
    data: any
}

const HFDStarIndexChart = ({ apiEndpoint, data }: ChartProps): ReactElement => {

    const [HFDPoints, setHFDPoints] = useState<Array<Array<number>>>([]);
    const [starIndexPoints, setStarIndexPoints] = useState<Array<Array<number>>>([]);
    const [xLimit, setXLimit] = useState<number>(20);
    const [yLimit, setYLimit] = useState<number>(10);

    const [HFDStarIndexChartOptions, setHFDStarIndexChartOptions] = useState({
        chart: {
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            }
        },
        legend: {
            show: true,
            labels: {
                colors: "#fff",
            },
            markers: {
                width: 7,
                height: 7,
            },
        },
        tooltip: {
            x: {
                show: false,
            }
        },
        stroke: {
            width: 2,
        },
        markers: {
            size: 0,
        },
        dataLabels: {
            enabled: false,
        },
        noData: {
            text: "No Data Available",
            style: {
                color: "#a3a3a3",
                fontSize: "14px",
            },
        },
        xaxis: {
            min: 0,
            labels: {
                show: true,
                style: {
                    colors: "#A3A3A3",
                    padding: "10px",
                },
            },
            tickAmount: 14,
        },
        yaxis: [
            {
                seriesName: 'HFD',
                labels: {
                    show: true,
                    style: {
                        colors: "#A3A3A3",
                        padding: "10px",
                    },
                    formatter: (val: number) => val.toFixed(2)
                }
            },
            {
                seriesName: 'Star Index',
                opposite: true,
                labels: {
                    show: true,
                    style: {
                        colors: "#A3A3A3",
                        padding: "10px",
                    },
                    formatter: (val: number) => val.toFixed(2)
                }
            }
        ]
    });

    const [HFDStarIndexChartSeries, setHFDStarIndexChartSeries] = useState([
        {
            name: "HFD",
            data: HFDPoints,
        },
        {
            name: "Star Index",
            data: starIndexPoints,
        }
    ])

    useEffect(() => {
        const getHFDStarIndexData = () => {
            const hfdCoords: Array<Array<number>> = [];
            const starIndexCoords: Array<Array<number>> = [];

            const activeScope = localStorage.getItem('activeScope');
            dataFetch(`${apiEndpoint}/data/hfd-starindex/${activeScope}?limit=20`)
                .then((res) => {
                    if (res.items.status === "200") {
                        Object.values(res.items.data).forEach((point: any, index: number) => {
                            hfdCoords.push([index, point.hfd]);
                            starIndexCoords.push([index, point.starIndex]);
                        })

                        setHFDPoints(hfdCoords);
                        setStarIndexPoints(starIndexCoords);
                    }
                })

        }

        setInterval(() => {
            getHFDStarIndexData();
        }, 60000);

        getHFDStarIndexData();
    }, [apiEndpoint, data]);

    useEffect(() => {
        setHFDStarIndexChartSeries([
            {
                name: "HFD",
                data: HFDPoints,
            },
            {
                name: "Star Index",
                data: starIndexPoints,
            }
        ])
    }, [HFDPoints, starIndexPoints]);

    return (
        <Card className="pt-2 px-1 full-height">
            <div>
                <h6 className="chart-card__header">
                    Star Index and HFD
                </h6>
            </div>
            <div className="chart-contianer">
                <ReactApexChart
                    options={HFDStarIndexChartOptions}
                    series={HFDStarIndexChartSeries}
                    type="line"
                    height="100%"
                />
            </div>
        </Card>
    )
}

export default HFDStarIndexChart;
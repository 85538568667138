/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";
import "status-indicator/styles.css";
import moment from "moment";
import momenttz from "moment-timezone";

export default function RainChart({ rain, rainYes, options, settings }) {
  const [RecentValue, setRecentValue] = useState({ value: 0, time: "", lastReading: "" });
  const [RecentValueHumidity, setRecentValueHumidity] = useState(0);

  const humdity = [];
  const humidityYes = [];
  const rainData = [];
  const rainYesData = [];
  const rainLimit1 = [
    { x: options.xaxis.min, y: 90 },
    { x: options.xaxis.max, y: 90 },
  ];
  const rainLimit2 = [
    { x: options.xaxis.min, y: 95 },
    { x: options.xaxis.max, y: 95 },
  ];

  if (rain.data !== undefined && rainYes.data !== undefined) {
    rain.data.forEach((item) => {
      rainData.push({
        x: parseInt(item.readingDate, 10),
        y: item.rain,
      });
      humdity.push({ x: parseInt(item.readingDate, 10), y: item.humidity });
    });
    rainYes.data.forEach((item) => {
      rainYesData.push({
        x: parseInt(item.readingDate, 10) + 86400,
        y: item.rain,
      });
      humidityYes.push({
        x: parseInt(item.readingDate, 10) + 86400,
        y: item.humidity,
      });
    });
  }
  const [apex, setApex] = useState({
    options: {
      ...options,
      colors: [
        "#D9302555",
        "#534DF855",
        "#D93025",
        "#534DF8",
        "#fb8500",
        "#AB271D",
      ],
      stroke: {
        ...options.stroke,
        dashArray: [0, 0, 0, 0, 3, 3],
      },
      yaxis: [
        {
          ...options.yaxis[0],
          seriesName: "yesterday1",
          show: false,
          min: 0,
          max: 100,
          labels: {
            ...options?.yaxis[0]?.labels,
            formatter(val) {
              return val?.toFixed(0);
            },
          },
        },
        {
          ...options.yaxis[0],
          seriesName: "yesterday2",
          show: false,
          opposite: true,
          reversed: true,
          min: 0,
          max: 4300,
          labels: {
            ...options?.yaxis[0]?.labels,
            formatter(val) {
              return val?.toFixed(0);
            },
          },
        },
        {
          ...options.yaxis[0],
          seriesName: "humidity",
          min: 0,
          max: 100,
          labels: {
            ...options?.yaxis[0]?.labels,
            formatter(val) {
              return val?.toFixed(0);
            },
          },
        },
        {
          ...options.yaxis[0],
          seriesName: "rain",
          opposite: true,
          reversed: true,
          min: 0,
          max: 4300,
          labels: {
            ...options?.yaxis[0]?.labels,
            formatter(val) {
              return val?.toFixed(0);
            },
          },
        },
        {
          ...options.yaxis[0],
          seriesName: "Rain Limit",
          show: false,
          min: 0,
          max: 100,
          labels: {
            ...options?.yaxis[0]?.labels,
            formatter(val) {
              return val;
            },
          },
        },
        {
          ...options.yaxis[0],
          seriesName: "Rain Limit",
          show: false,
          min: 0,
          max: 100,
          labels: {
            ...options?.yaxis[0]?.labels,
            formatter(val) {
              return val;
            },
          },
        },
      ],
    },
    series: [{ name: "mm", data: [] }],
  });

  let indicator;
  const duration = parseInt(
    moment
      .duration(
        moment(new Date(), "HH:mm:ss a").diff(
          moment(RecentValue.time, "HH:mm:ss a")
        )
      )
      .asMinutes() % 60,
    10
  );

  const isStaleData = (readingDate, stalePeriod, tag = '') => {
    const lastReading = moment.unix(readingDate).tz(settings.station_timezone).format("DD/MM/YY HH:mm:ss a").toString();
    const currentTime = moment(new Date()).tz(settings.station_timezone).format("DD/MM/YY HH:mm:ss a").toString();
    const readingInterval = moment.duration(
      moment(currentTime, "DD/MM/YY HH:mm:ss a")
        .diff(moment(lastReading, "DD/MM/YY HH:mm:ss a"))
    ).asMinutes();

    return (Math.floor(readingInterval) > stalePeriod);
  }

  const clockIndicator = (lastUpdated) => {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__cloud-1">
            Last updated {moment.unix(lastUpdated).tz(settings.station_timezone).format("DD-MMM HH:mm:ssa")}
          </Tooltip>
        }
      >
        <Clock className="clock__icon" />
      </OverlayTrigger>
    );
  }

  if (isStaleData(RecentValue.lastReading, 15, "Recent Value")) {
    indicator = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip__rain">
            Last updated {RecentValue.time}
          </Tooltip>
        }
      >
        {clockIndicator(RecentValue.lastReading)}
      </OverlayTrigger>
    );
  } else {
    if (parseInt(RecentValueHumidity, 10) > 95) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__rain">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            negative
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (parseInt(RecentValueHumidity, 10) > 90) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__rain">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            intermediary
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
    if (parseInt(RecentValueHumidity, 10) < 90) {
      indicator = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip__rain">
              Last updated {RecentValue.time}
            </Tooltip>
          }
        >
          <status-indicator
            active
            positive
            pulse
            style={{ margin: "0 10px" }}
          />
        </OverlayTrigger>
      );
    }
  }

  useEffect(() => {
    setApex({
      ...apex,
      options: {
        ...apex.options,
        xaxis: {
          ...apex.options.xaxis,
          min: options.xaxis.min,
          max: options.xaxis.max,
        },
      },
      series: [
        {
          name: "yesterday1",
          data: humidityYes,
        },
        {
          name: "yesterday2",
          data: rainYesData,
        },
        {
          name: "Humdity (L)",
          data: humdity,
        },
        {
          name: "Rain Sensor (R)",
          data: rainData,
        },
        {
          name: "Rain Limit",
          data: rainLimit1,
        },
        {
          name: "Rain Limit",
          data: rainLimit2,
        },
      ],
    });

    setRecentValue(
      rainData[0] === undefined
        ? { value: "", time: "" }
        : {
          value: rainData[rainData.length - 1].y,
          time: momenttz(new Date(rainData[rainData.length - 1].x * 1000))
            .tz(`${settings.station_timezone}`)
            .format("HH:mm"),
          lastReading: rainData[rainData.length - 1].x,
        }
    );
    setRecentValueHumidity(
      humdity[0] === undefined ? "" : humdity[humdity.length - 1].y
    );
  }, [rain, rainYes, options]);
  return (
    <Card className="pt-2 px-1 full-height">
      <div>
        <h6 className="chart-card__header">
          Relative Humidity/Rain
          <span className="title__recent-value">
            RH:
            <span className="chart-card__header-value">
              {RecentValueHumidity === "" ? "_" : RecentValueHumidity}
            </span>
            %
            {RecentValueHumidity !== "" ? (
              indicator
            ) : (
              <Clock className="clock__icon" />
            )}
          </span>
        </h6>
      </div>
      <div className="chart-contianer">
        <Chart
          id="rain-chart"
          options={apex.options}
          series={apex.series}
          type="line"
          height="100%"
        />
      </div>
    </Card>
  );
}

RainChart.propTypes = {
  rain: PropTypes.shape({ data: PropTypes.shape([]) }).isRequired,
  rainYes: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  options: PropTypes.shape({
    chart: PropTypes.shape({}),
    stroke: PropTypes.shape({}),
    length: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
  }).isRequired,
};

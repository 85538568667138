/* eslint-disable react/prop-types */
import React, { FC } from "react";
import { Container, Row } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import moment from "moment";
import Charts from "./shared/chartsWeeklyReports/Charts";
import { OPTIONS_WEEKLY } from "./shared/options";
import { from48, nowunix } from "./shared/intervals";
import { SettingsContext } from "../hooks/settings-context";

const ChartsTemplate: FC<RouteComponentProps> = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const from = params.get("from") || from48;
  const to = params.get("to") || nowunix;
  const options = {
    ...OPTIONS_WEEKLY,
    legend: {
      ...OPTIONS_WEEKLY.legend,
      horizontalAlign: "center",
      floating: true,
      position: "top",
      labels: {
        colors: "#000",
      },
    },
    xaxis: {
      ...OPTIONS_WEEKLY.xaxis,
      min: from,
      max: to,
      labels: {
        ...OPTIONS_WEEKLY.xaxis.labels,
        style: {
          ...OPTIONS_WEEKLY.xaxis.labels.style,
          colors: "#000",
        },
      },
    },
    yaxis: [
      {
        ...OPTIONS_WEEKLY.yaxis[0],
        labels: {
          style: {
            colors: "#000",
          },
        },
      },
    ],
  };
  const url = location.search || `?from=${from}&to=${to}`;

  return (
    <SettingsContext.Consumer>
      {({ data }) => (
        <div style={{ backgroundColor: "white" }}>
          <Container fluid>
            <Row className="mx-3">
              <div className="center">
                <h1>{data.app_name}</h1>
                <p>
                  <span>
                    From: {moment(Number(from) * 1000).format("DD/MM/Y HH:mm")}
                  </span>
                  <span style={{ marginLeft: "10px" }}>
                    To: {moment(Number(to) * 1000).format("DD/MM/Y HH:mm")}
                  </span>
                </p>
              </div>
              <Charts options={options} url={url} />
            </Row>
          </Container>
        </div>
      )}
    </SettingsContext.Consumer>
  );
};

export default withRouter(ChartsTemplate);

import React, { FC, ReactElement } from "react";
import { Container, Row, Col } from "react-bootstrap";

import AllSkyViewWidget from "./shared/AllSkyViewWidget";
import ChartWapper from "./shared/charts/ChartWapper";
import Footer from "./Footer";
import { SettingsContext } from "../hooks/settings-context";

const radios = [
  { name: "Last image", value: "1" },
  { name: "Last hour", value: "2" },
  { name: "Last night", value: "3" },
];

const AllSky: FC = (): ReactElement => {
  return (
    <SettingsContext.Consumer>
      {({ apiEndpoint, data }) => (
        <>
          <Container className="allSky" fluid>
            <Row
              style={{
                position: "relative",
                top: "80px",
                height: "calc(100vh - 134px)",
              }}
            >
              <Col sm={6} className="full-height">
                {data.station_timezone && (
                  <ChartWapper
                    apiEndpoint={apiEndpoint}
                    settings={{
                      station_timezone: data.station_timezone,
                    }}
                  />
                )}
              </Col>
              <Col sm={6} className="full-height">
                <AllSkyViewWidget
                  apiEndpoint={apiEndpoint}
                  settings={{
                    station_timezone: data.station_timezone,
                    allsky_last_image: data.allsky_last_image,
                    science_last_image: data.science_last_image,
                    science_last_graph: data.science_last_graph,
                    allsky_asset_directory: data.allsky_asset_directory,
                    allSky_last_video: data.allSky_last_video,
                  }}
                  radios={radios}
                />
              </Col>
            </Row>
          </Container>
          <Footer />
        </>
      )}
    </SettingsContext.Consumer>
  );
};

export default AllSky;

/* eslint-disable react/no-array-index-key */
import React, { useState, ReactElement } from "react";
import { ButtonGroup, ToggleButton, Image } from "react-bootstrap";

type AppProp = {
  style: {
    width?: string;
    height?: string;
  };
  details: {
    src: {
      type: string;
      url: string;
    }[];
    radios: { name: string; value: string }[];
  };
};

export default function WeatherWidget({
  style,
  details,
}: AppProp): ReactElement {
  const [radioValue, setRadioValue] = useState("0");
  const { radios } = details;

  let src;
  let index = 0;
  const len = details.src.length;
  while (index < len) {
    if (radioValue === index.toString()) {
      src = details.src[index];
    }
    index += 1;
  }

  return (
    <>
      {src?.type === "iframe" ? (
        <iframe
          title="Satellite"
          src={src.url}
          frameBorder="0"
          scrolling="yes"
          style={style}
        />
      ) : (
        <Image className="weather-widget" src={src?.url} />
      )}
      <ButtonGroup className="ButtonGroup-custom pt-1" toggle>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="primary"
            size="sm"
            name="radio"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => setRadioValue(e.currentTarget.value)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  );
}

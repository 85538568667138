/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from "react";

import moment from "moment";
import momenttz from "moment-timezone";
import { SettingsContext } from "../../hooks/settings-context";

export default function Clock() {
  const [date, setDate] = React.useState(new Date());
  function tick() {
    setDate(new Date());
  }
  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  return (
    <SettingsContext.Consumer>
      {({ data }) =>
        data.station_timezone && (
          <p className="main-clock">
            {momenttz(date)
              .tz(`${data.station_timezone}`)
              .format("ddd D MMM, YYYY HH:mm")}
            , UTC {moment().utc().format("HH:mm:ss")}
          </p>
        )
      }
    </SettingsContext.Consumer>
  );
}

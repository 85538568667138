import React, { ReactElement } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";

import WidgetCardLarge from "./shared/WidgetCardLarge";
import StormStation5NGX from "./shared/StormStation5NGX";
import Footer from "./Footer";
import { SettingsContext } from "../hooks/settings-context";

export default function Lightning(): ReactElement {
  return (
    <SettingsContext.Consumer>
      {({ data }) => (
        <>
          <Container fluid>
            <Row
              style={{
                position: "relative",
                top: "95px",
              }}
            >
              <Col xs={12} md={6}>
                <WidgetCardLarge
                  settings={{
                    cctv_stream_1: data.cctv_stream_1,
                    cctv_stream_2: data.cctv_stream_2,
                  }}
                  data={{
                    widget: "Blitzortung",
                    src: `https://map.blitzortung.org/index.php?interactive=0&NavigationControl=0&FullScreenControl=0&Cookies=0&InfoDiv=1&MenuButtonDiv=1&ScaleControl=1&LinksCheckboxChecked=1&LinksRangeValue=10&MapStyle=3&MapStyleRangeValue=4&Advertisment=0#5/${data.station_lat}/${data.station_long}`,
                    style: {
                      width: "100%",
                      height: "calc(100vh - 270px)",
                    },
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                <Card className="pb-4 pt-3 px-3">
                  <Card.Body className="p-0">
                    <Card.Title
                      className="h6"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      StormStation5
                      <a href="/" className="custom-btn__circular minus">
                        {" "}
                      </a>
                    </Card.Title>
                  </Card.Body>
                  <StormStation5NGX height="calc(100vh - 270px)" />
                </Card>
              </Col>
            </Row>
          </Container>
          <Footer />
        </>
      )}
    </SettingsContext.Consumer>
  );
}

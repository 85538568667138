/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";

type AppProps = {
  height: string;
};
export default function StormStation5NGX({ height }: AppProps) {
  return (
    <div>
      <div
        className="leaflet-container leaflet-fade-anim"
        id="map"
        style={{
          left: "0px",
          top: "0px",
          width: "auto",
          height,
          position: "relative",
          backgroundColor: "black",
        }}
      >
        <canvas
          id="pnlClockCanvas"
          width="220"
          height="30"
          style={{
            zIndex: 2,
            position: "relative",
            left: "5px",
            top: "5px",
          }}
        >
          Your browser does not support HTML5 Canvas.
        </canvas>

        <canvas
          id="pnlCanvas"
          width="245"
          height="330"
          style={{
            zIndex: 2,
            position: "relative",
            left: "450px",
            top: "5px",
          }}
        >
          Your browser does not support HTML5 Canvas.
        </canvas>

        <canvas
          id="pnlSymCanvas"
          width="245"
          height="65"
          style={{
            zIndex: 2,
            position: "relative",
            left: "450px",
            top: "340px",
          }}
        >
          Your browser does not support HTML5 Canvas.
        </canvas>
      </div>
    </div>
  );
}

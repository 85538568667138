/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/ban-types */
import React, { ReactElement, useEffect, useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import moment from "moment";
import SqmChart from "./SqmChart";
import NsmChart from "./NsmChart";
import TempChart from "./TempChart";
import WindChart from "./WindChart";
import CloudChart from "./CloudChart";
import RainChart from "./RainChart";
import AlldylosChart from "./AlldylosChart";
import dataFetch from "../../../actions/fetchDataActions";
import { SettingsContext } from "../../../hooks/settings-context";

type AppProps = {
  apiEndpoint: string;
  options: object;
  url: string;
};

export default function ChartWapper({ apiEndpoint, options, url }: AppProps): ReactElement {
  const defaultState = { error: null, status: true, items: [] };

  const [weeklySqmData, setweeklySqmData] = useState(defaultState);
  const [weeklynsmData, setweeklynsmData] = useState(defaultState);
  const [weeklyalldylosData, setweeklyalldylosData] = useState(defaultState);
  const [weeklysoloData, setweeklysoloData] = useState(defaultState);

  const [delay, setDelay] = useState(false);

  useEffect(() => {
    const baseUrl = `${apiEndpoint}/data`;
    
    if (delay === true) {
      const interval = setInterval(() => {
        // weeklySqmData
        dataFetch(`${baseUrl}/sqm${url}`).then((data) => {
          setweeklySqmData(data);
        });

        // weeklynsmData
        dataFetch(`${baseUrl}/nsm${url}`).then((data) => {
          setweeklynsmData(data);
        });

        // weeklyalldylosData
        dataFetch(`${baseUrl}/dylos${url}`).then((data) => {
          setweeklyalldylosData(data);
        });

        // weeklysoloData
        dataFetch(`${baseUrl}/solo${url}`).then((data) => {
          setweeklysoloData(data);
        });
      }, 120000);
      return () => {
        clearInterval(interval);
      };
    }
    // weeklySqmData
    dataFetch(`${baseUrl}/sqm${url}`).then((data) => {
      setweeklySqmData(data);
    });

    // weeklynsmData
    dataFetch(`${baseUrl}/nsm${url}`).then((data) => {
      setweeklynsmData(data);
    });

    // weeklyalldylosData
    dataFetch(`${baseUrl}/dylos${url}`).then((data) => {
      setweeklyalldylosData(data);
    });

    // // weeklysoloData
    dataFetch(`${baseUrl}/solo${url}`).then((data) => {
      setweeklysoloData(data);
    });
  }, [delay,url]);

  return (
    <SettingsContext.Consumer>
    {({ data }) =>
     data.station_timezone && (
    <>
      {weeklySqmData.status === true ? (
        <Spinner
          animation="grow"
          variant="info"
          style={{
            height: "200px",
            width: "200px",
          }}
        />
      ) : (
        <Row className="gy-2 weekly">
          <div className="reports" style={{ height: "85%" }}>
          <Col sm={12} style={{position: "relative", top: "-25px"}}>
            {weeklysoloData.status === true ? (
              "Loading ..."
            ) : (
              <CloudChart
              settings={{
                station_timezone: data.station_timezone,
              }}
                options={options}
                cloud={weeklysoloData.items}
                setDelay={setDelay}
              />
            )}
          </Col>
          <Col sm={12} style={{position: "relative", top: "-60px"}}>
            {weeklysoloData.status === true ? (
              ""
            ) : (
              <RainChart
              settings={{
                station_timezone: data.station_timezone,
              }}
                options={options}
                rain={weeklysoloData.items}
              />
            )}
          </Col>
          <Col sm={12} style={{position: "relative", top: "-96px", left: "19px"}}>
            {weeklysoloData.status === true ? (
              ""
            ) : (
              <WindChart
              settings={{
                station_timezone: data.station_timezone,
              }}
                options={options}
                wind={weeklysoloData.items}
              />
            )}
          </Col>
          <Col sm={12} style={{position: "relative", top: "-125px", left: "15px"}}>
            {weeklysoloData.status === true ? (
              ""
            ) : (
              <TempChart
              settings={{
                station_timezone: data.station_timezone,
              }}
                options={options}
                temps={weeklysoloData.items}
              />
            )}
          </Col>
          <Col sm={12} style={{position: "relative", top: "-160px", left: "20px"}}>
            {weeklynsmData.status === true ? (
              ""
            ) : (
              <NsmChart
              settings={{
                station_timezone: data.station_timezone,
              }}
                options={options}
                nsm={[]}
              />
            )}
          </Col>
          <Col sm={12} style={{position: "relative", top: "-220px", left: "10px"}}>
            {weeklyalldylosData.status === true ? (
              ""
            ) : (
              <AlldylosChart
              settings={{
                station_timezone: data.station_timezone,
              }}
                options={options}
                alldylos={weeklyalldylosData.items}
              />
            )}
          </Col>
          <Col sm={12} style={{ position: "relative", top: "-280px" }}>
              <SqmChart
              settings={{
                station_timezone: data.station_timezone,
              }}
                options={options}
                sqm={weeklySqmData.items}
              />
          </Col>
          </div>
        </Row>
      )}
    </>
    )
    }
  </SettingsContext.Consumer>
  );
}
